<template>
  <b-navbar fixed-top type="is-primary" transparent>
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/admin/dashboard' }">
        <img
            src="../assets/logo_small.gif"
            alt="LITEBLOX"
        >
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item href="/admin/dashboard">
        Dashboard
      </b-navbar-item>
      <b-navbar-item href="/admin/data">
        Telemetry Data
      </b-navbar-item>
      <b-navbar-item href="/admin/profile">
        Profile
      </b-navbar-item>
      <b-navbar-item @click="logout()">
        Logout
      </b-navbar-item>
    </template>

  </b-navbar>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
      name: "NavBar",
      components:{},
      methods:{
      ...mapGetters('user', ['isAuthorized', 'isAdmin']),
      ...mapActions('user', ['setUser']),
        logout: function() {
          this.$http.get('/signOut')
          this.setUser({})
          this.$router.push('/')
        }
      }
    }
</script>

<style scoped>  

</style>
