<template>
    <section class="scrollable-container" :class="{ 'is-reduced': isReduced }">
        <div class="content-wrapper">
            <b-tabs>
                <b-tab-item label="Datasets">
                  <section class="is-fullheight is-fullwidth">
                    <div class="columns is-1 dashboard-columns">
                        <div class="column is-narrow" style="flex: 0 0 auto;">
                          <div>
                          <b-field :grouped=true class="is-1">
                            <b-input placeholder="Search..."
                                   type="search"
                                   icon-pack="fas"
                                   v-model="q"
                                   @keyup.native.enter="loadAsyncData(true)"
                                   icon-clickable
                                   @icon-click="loadAsyncData(true)"
                                   icon="search">
                            </b-input>

                            <b-select @change="loadAsyncData(true)" v-model="qc">
                              <option value="ID">ID</option>
                              <option value="dname">Name</option>
                              <option value="dserial">Serial</option>
                              <option value="createdAt">Date</option>
                            </b-select>
                          </b-field>

                          <b-table
                              :data="data"
                              ref="table"
                              :loading="loading"
                              class="my-table is-medium is-1 telemetry-main-table "
                              :striped="true"
                              :hoverable="true"
                              :selected.sync="selected"
                              :debounce-search="1000"
                              :sticky-header="true"
                              :height="tableHeight"
                              @resize="onResize"
                              focusable
                              @click="clickedDetails"

                              :opened-detailed="defaultOpenedDetails"
                              detailed
                              detail-key="id"
                              @details-open="(row) => closeAllOtherDetails(row)"
                              :show-detail-icon="false"
                              paginated
                              backend-pagination
                              :total="total"
                              :per-page="pageSize"
                              :current-page="page"
                              :key="page + '-' + q"
                              @page-change="onPageChange"
                              aria-next-label="Next page"
                              aria-previous-label="Previous page"
                              aria-page-label="Page"
                              aria-current-label="Current page"
                              backend-sorting
                              :default-sort-direction="defaultSortOrder"
                              :default-sort="[sortField, sortOrder]"
                              @sort="onSort">

                        <b-table-column 
                            field="id" 
                            label="ID" 
                            v-slot="props" 
                            width="40" 
                            sticky 
                            sortable 
                            class="is-sticky-column-one"
                            :custom-header-class="'header-left-align'">
                          <b-tooltip v-if="props.row.sentactive" :label="'service request'" position="is-right">
                            <span class="tag is-success is-status is-small is-sticky-column-two">{{ props.row.id }}</span>
                            <span style="font-size: 0.5em; color: #ff99aa;" class="is-small">service req.</span>
                          </b-tooltip>
                          <span v-else class="tag is-info is-status is-small is-sticky-column-one">{{ props.row.id }}</span>
                        </b-table-column>

                        <b-table-column field="dname" label="Device Name" sortable v-slot="props">
                          {{ props.row.dname }}
                        </b-table-column>

                        <b-table-column field="dserial" label="Serial ADoc" sortable v-slot="props">
                          {{ props.row.dserial }}
                        </b-table-column>

                        <b-table-column field="createdAt" label="Date" sortable v-slot="props">
                          {{ new Date(props.row.createdAt).toLocaleDateString('de-DE')}}
                        </b-table-column>

                        <b-table-column field="createdAt" label="Time" v-slot="props">
                          {{ new Date(props.row.createdAt).toLocaleTimeString('de-DE')}}
                        </b-table-column>

                      </b-table>
                      </div>
                    </div>

                    <div class="column is-narrow" style="flex: 0 0 auto;">
                      <b-message title="Device" type="is-primary-light" class="no-paddding is-small no-margin is-marginless" :closable="false">
                          <b-table
                            :data="selectedDetails"
                            ref="deviceTable"
                            class="device-table"
                            narrowed
                            card-layout
                            :hoverable="true">
                            <b-table-column field="dname" label="Device Name" sortable centered v-slot="props">
                              {{ props.row.dname }}
                            </b-table-column>
                            <b-table-column field="dserial" label="Serial Akkudoc" sortable centered v-slot="props">
                              {{ props.row.dserial }}
                            </b-table-column>
                            <b-table-column field="revision" label="Revision No." centered v-slot="props">
                              {{ props.row.revision }}
                            </b-table-column>
                            <b-table-column field="firmwareversion" label="FW Version" sortable centered v-slot="props">
                              {{ props.row.firmwareversion }}
                            </b-table-column>
                            <b-table-column field="firmwarevariante" label="FW Variation" sortable centered v-slot="props">
                              {{ props.row.firmwarevariante }}
                            </b-table-column>
                            <b-table-column field="dpassword" label="Password" centered v-slot="props">
                              {{ props.row.dpassword }}
                            </b-table-column>
                            <b-table-column field="psantitheftenabled" label="AVAT" centered v-slot="props">
                              {{ props.row.psantitheftenabled }}
                            </b-table-column>
                            <b-table-column field="killswitchenabled" label="IKOS" centered v-slot="props">
                              {{ props.row.killswitchenabled }}
                            </b-table-column>
                            <b-table-column field="soc" label="SOC" centered v-slot="props" width="80">
                              <b-tooltip :label="`Raw count: ${props.row.rawSoc}`">
                                <span class="percentage-value" :style="{ backgroundColor: `rgba(72, 199, 142, ${props.row.socHighlight})` }">
                                  {{ props.row.soc }}
                                </span>
                              </b-tooltip>
                            </b-table-column>
                            <b-table-column field="dstatus" label="State" centered v-slot="props">
                              {{ props.row.dstatus }}
                            </b-table-column>
                            <b-table-column field="reason" label="Reason" centered v-slot="props">
                              {{ props.row.reason }}
                            </b-table-column>
                          </b-table>
                        </b-message>
                      <b-message title="Current" type="is-primary-light" class="no-paddding scrollableContent is-small is-marginless" :closable="false">
                        <b-table
                            :data="selectedCurrentInfo"
                            ref="currentTable"
                            class="current-table"
                            narrowed
                            :mobile-cards="false"
                            :sticky-header="true"
                            :hoverable="true">
                          <b-table-column field="verticalHeader" label="" class="verticalHeader" sticky v-slot="props">
                            {{ props.row.verticalHeader }}
                          </b-table-column>
                          <b-table-column field="now" label="NOW" centered v-slot="props" >
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'now', props.row.now) && !shouldHighlightRed(props.row.verticalHeader, 'now', props.row.now),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'now', props.row.now)
}">
                              {{ props.row.now  }}
                            </span>
                          </b-table-column>
                          <b-table-column field="min" label="MIN" centered v-slot="props">
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'min', props.row.min) && !shouldHighlightRed(props.row.verticalHeader, 'min', props.row.min),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'min', props.row.min)
}">
                              {{ props.row.min }}
                            </span>
                          </b-table-column>
                          <b-table-column field="max" label="MAX" centered v-slot="props">
                            <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow(props.row.verticalHeader, 'max', props.row.max) && !shouldHighlightRed(props.row.verticalHeader, 'max', props.row.max),
  'highlighted-value-red': shouldHighlightRed(props.row.verticalHeader, 'max', props.row.max)
}">
                              {{ props.row.max }}
                            </span>
                          </b-table-column>
                        </b-table>
                      </b-message>

                    </div>
                    <div class="column is-narrow" style="flex: 0 0 auto;">
                      <b-message title="Lifecycle" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <b-tabs size="is-small" class="block is-paddingless lifecycle-tabs">
                          <b-tab-item label="Info" class="is-paddingless">
                          <b-table
                              :data="selectedLifecycleStarttime"
                              ref="lifecycleTable"
                              class="lifecycle-table"
                              narrowed
                              card-layout
                              deep
                              :hoverable="true">
                            <b-table-column field="lifecycleStart" label="Lifecycle start (0h)" centered v-slot="" :visible="lifecycleDataLoading">
                               loading ...
                            </b-table-column>
                            <b-table-column field="lifecycleStart" label="Lifecycle start (0h)" centered v-slot="props" :visible="!lifecycleDataLoading">
                              {{ new Date(props.row.lifecycleStart).toLocaleDateString('de-DE') }} {{ new Date(props.row.lifecycleStart).toLocaleTimeString('de-DE')}}
                            </b-table-column>
                            <b-table-column field="dtcycle" label="Δt Cycle" centered v-slot="props">
                              {{ props.row.deltaTCycleHours }} hours
                            </b-table-column>
                            <b-table-column field="timeSinceBoot" label="time since boot" centered v-slot="props">
                              {{ props.row.dayssinceboot }} days
                            </b-table-column>
                            <b-table-column field="timeAwake" label="time awake" centered v-slot="props">
                              {{ props.row.hoursawake }} hours
                            </b-table-column>
                            <b-table-column field="durationDeepDecharge" label="deep discharge" centered v-slot="" :visible="deepDechargeDataLoading">
                              loading ...
                            </b-table-column>
                            <b-table-column field="durationDeepDecharge" label="deep discharge" centered v-slot="props" :visible="!deepDechargeDataLoading">
                              {{ props.row.durationDeepDeecharge > -1 ? props.row.durationDeepDeecharge + " days" : "n.v."}}
                            </b-table-column>
                            <b-table-column field="startCounter" label="Start counter" centered v-slot="props">
                              {{ props.row.startCounter }}
                            </b-table-column>
                            <b-table-column field="chargeCounter" label="Charge counter" centered v-slot="props">
                              {{ props.row.chargeCounter }}
                            </b-table-column>
                          </b-table>
                          </b-tab-item>
                            <b-tab-item label="Buckets" class="is-paddingless">
                              <b-table :data="selectedBuckets" narrowed hoverable>
                                  <b-table-column field="socLabel" label="SOC Range" centered v-slot="props" width="80">
                                    <span class="label-color">{{ props.row.socLabel }}</span>
                                  </b-table-column>
                                  <b-table-column field="soc" label="Distribution" centered v-slot="props" width="80">
                                    <b-tooltip :label="`Raw count: ${props.row.rawSoc}`">
                                      <span class="percentage-value" :style="{ backgroundColor: `rgba(72, 199, 142, ${props.row.socHighlight})` }">
                                        {{ props.row.soc }}
                                      </span>
                                    </b-tooltip>
                                  </b-table-column>
                                  <b-table-column field="temp" label="Distribution" centered v-slot="props" width="80">
                                    <b-tooltip :label="`Raw count: ${props.row.rawTemp}`">
                                      <span class="percentage-value" :style="{ backgroundColor: `rgba(72, 199, 142, ${props.row.tempHighlight})` }">
                                        {{ props.row.temp }}
                                      </span>
                                    </b-tooltip>
                                  </b-table-column>
                                  <b-table-column field="tempLabel" label="Temp Range" centered v-slot="props" width="80">
                                    <span class="label-color">{{ props.row.tempLabel }}</span>
                                  </b-table-column>
                              </b-table>
                            </b-tab-item>
                          </b-tabs>

                      </b-message>

                      <b-message :title=selectedCustomerID.toString() type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <template #header>
                          <span v-if="selectedIsReseller">Reseller № {{selectedCustomerID.toString()}}</span>
                          <span v-else>Customer № {{selectedCustomerID.toString()}}</span>
                        </template>
                        <b-tabs size="is-small" class="block is-paddingless customer-tabs">
                          <b-tab-item label="Customer Info" class="is-paddingless">
                            <b-table
                                :data="selectedCustomerData"
                                ref="customerTable"
                                class="customer-info"
                                narrowed
                                :striped="true"
                                card-layout
                                :hoverable="true">
                              <b-table-column field="firstname" label="First name" centered v-slot="props">
                                {{ props.row.firstName }}
                              </b-table-column>
                              <b-table-column field="lastname" label="Last name" centered v-slot="props">
                                {{ props.row.lastName }}
                              </b-table-column>
                              <b-table-column field="email" label="Email" centered v-slot="props">
                                {{ props.row.email }}
                              </b-table-column>
                              <b-table-column field="phone" label="Phone" centered v-slot="props">
                                {{ props.row.phone }}
                              </b-table-column>
                              <b-table-column field="company" label="Company" centered v-slot="props">
                                {{ props.row.company }}
                              </b-table-column>
                              <b-table-column field="country" label="Country" centered v-slot="props">
                                {{ props.row.country }}
                              </b-table-column>
                            </b-table>
                          </b-tab-item>
                          <b-tab-item label="Vehicle">
                            <b-table
                                :data="selectedVehicleData"
                                ref="vehicleTable"
                                class="vehicle-info"
                                narrowed
                                card-layout
                                :sticky-header="true"
                                height="150px"
                                :hoverable="true">
                              <b-table-column field="brand" label="Brand" centered v-slot="props">
                                {{ props.row.brand }}
                              </b-table-column>
                              <b-table-column field="model" label="Model" centered v-slot="props">
                                {{ props.row.model }}
                              </b-table-column>
                              <b-table-column field="buildYear" label="Build year" centered v-slot="props">
                                {{ props.row.build }}
                              </b-table-column>
                              <b-table-column field="engine" label="Engine" centered v-slot="props">
                                {{ props.row.engine }}
                              </b-table-column>
                              <b-table-column field="fuel" label="Fuel" centered v-slot="props">
                                {{ props.row.fuel }}
                              </b-table-column>
                              <b-table-column field="engineConcept" label="Engine concept" centered v-slot="props">
                                {{ props.row.engineConcept }}
                              </b-table-column>
                              <b-table-column field="engineCylinders" label="Cylinders" centered v-slot="props">
                                {{ props.row.engineCylinders }}
                              </b-table-column>
                              <b-table-column field="engineSize" label="Engine size" centered v-slot="props">
                                {{ props.row.engineSize }}
                              </b-table-column>
                              <b-table-column field="usage" label="Usage" centered v-slot="props">
                                {{ props.row.usage }}
                              </b-table-column>
                              <b-table-column field="consumersStandby" label="Consumers standby" centered v-slot="props">
                                {{ props.row.consumersStandby }}
                              </b-table-column>
                              <b-table-column field="electricalPeriphery" label="Electrical periphery" centered v-slot="props">
                                {{ props.row.electricalPeriphery }}
                              </b-table-column>
                              <b-table-column field="info" label="Info" centered v-slot="props">
                                {{ props.row.info }}
                              </b-table-column>
                            </b-table>
                          </b-tab-item>
                        </b-tabs>

                        <b-message v-if="selectedCustomerMessage !== ''" title="Customer message" type="is-danger" class="no-paddding is-small is-marginless" :closable="false">
                          {{ selectedCustomerMessage }}
                        </b-message>
                        <b-message v-else title="Customer message" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                          {{ selectedCustomerMessage }}
                        </b-message>

                      </b-message>

                    </div>

                    <div class="column history-column">
                      <b-message title="History" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                          <div v-if="selectedPshistoryData.length === 0 && !pshistoryDataLoading">
                              <p>No history data available</p>
                          </div>
                          <b-table
                              v-else-if="!pshistoryDataLoading"
                              :data="selectedPshistoryData"
                              ref="historyTable"
                              class="is-marginless is-paddingless history-table"
                              narrowed
                              :mobile-cards="false"
                              :sticky-header="true"
                              height="260px"
                              :hoverable="true"
                              :default-sort-direction="defaultSortOrder"
                              :default-sort="['ts', sortOrder]">

                            <b-table-column field="ts" label="Time" centered sortable v-slot="" :visible="pshistoryDataLoading">loading ...</b-table-column>
                            <b-table-column field="ts" label="Time" centered sortable v-slot="props" :visible="!pshistoryDataLoading">
                              {{ typeof props.row.time === "undefined" ? "loading data ... " : props.row.ts ? formatDuration(props.row.ts) : new Date(props.row.time).toLocaleString('de-DE') }}
                            </b-table-column>
                            <b-table-column field="status" label="Status" centered sortable v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="status" label="Status" centered sortable v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="status" label="Status" sortable v-slot="props" :visible="!pshistoryDataLoading">
                              {{props.row.state}}
                            </b-table-column>
                            <b-table-column field="reason" label="Reason" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="reason" label="Reason" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.reason }}
                            </b-table-column>
                            <b-table-column field="value" label="Value" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="value" label="Value" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.value }}
                            </b-table-column>
                            <b-table-column field="current" label="Current" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="current" label="Current" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              {{ props.row.current }}
                            </b-table-column>
                            <b-table-column field="voltages" label="Voltages" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="voltages" label="Voltages" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              <a>
                                {{ props.row.voltages ? props.row.voltages.toString() : "" }}
                              </a>
                            </b-table-column>
                            <b-table-column field="temps" label="Temps" sortable centered v-slot="" :visible="pshistoryDataLoading"></b-table-column>
                            <b-table-column field="temps" label="Temps" sortable centered v-slot="props" :visible="!pshistoryDataLoading">
                              <b-tooltip :label="Array.isArray(props.row.temperatures) ? props.row.temperatures.join(', ') : props.row.temperatures" 
                                         position="is-left" 
                                         class="custom-tooltip">
                                <a>
                                  {{ props.row.temperatures ? props.row.temperatures.toString() : ""  }}
                                </a>
                              </b-tooltip>
                            </b-table-column>

                          </b-table>
                      </b-message>

                      <b-message title="Selftest" type="is-primary-light" class="no-paddding is-small is-marginless" :closable="false">
                        <b-table
                            :data="selectedSelftestResult"
                            ref="currentTable"
                            narrowed
                            card-layout
                            :sticky-header="true"
                            height="260px"
                            width="200px"
                            class="is-narrow selftest-table"
                            :hoverable="true">
                          <b-table-column field="bmsCellOv" label="bmsCellOv" v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCellOv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCellOv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCellUv" label="bmsCellUv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCellUv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCellUv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCbOpen" label="bmsCbOpen" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCbOpen == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCbOpen == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsCbShort" label="bmsCbShort" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsCbShort == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsCbShort == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsOt" label="bmsOt" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsOt == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsOt == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsUt" label="bmsUt" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsUt == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsUt == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault1" label="bmsFault1" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault1 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault1 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault2a" label="bmsFault2a" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault2a == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault2a == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="bmsFault2b" label="bmsFault2b" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].bmsFault2b == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].bmsFault2b == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="cellUv" label="cellUv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].cellUv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].cellUv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="cellOv" label="cellOv" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].cellOv == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].cellOv == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="generic" label="generic" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].generic == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].generic == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag1" label="fs4500Diag1" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag1 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag1 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag2" label="fs4500Diag2" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag2 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag2 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag3" label="fs4500Diag3" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag3 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag3 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                          <b-table-column field="fs4500Diag4" label="fs4500Diag4" centered v-slot="">
                            <b-icon
                                v-if="selectedSelftestResult[0].fs4500Diag4 == 0"
                                icon="check"
                                size="is-small"
                                type="is-success">
                            </b-icon>
                            <b-icon
                                v-else-if="selectedSelftestResult[0].fs4500Diag4 == 1"
                                icon="times"
                                size="is-small"
                                type="is-danger">
                            </b-icon>
                          </b-table-column>
                        </b-table>
                      </b-message>
                    </div>
                </div>
              </section>

            </b-tab-item>

            <b-tab-item label="Export">
                <template v-if="selected">
                    <div class="export-controls">
                        <b-button 
                            size="is-small"
                            type="is-primary"
                            icon-left="copy"
                            @click="copyToClipboard">
                            Copy JSON
                        </b-button>
                        <b-button 
                            size="is-small"
                            type="is-primary"
                            icon-left="download"
                            @click="downloadJSON">
                            Download JSON
                        </b-button>
                    </div>
                    <pre class="json-viewer"><code>{{ formatJSON(selected) }}</code></pre>
                </template>
                <template v-else>
                    <p>No item is selected</p>
                </template>
            </b-tab-item>
        </b-tabs>
      </div>

  </section>
</template>

<script>
    export default {
        name: 'TelemetrydataManager',
        data() {
            return {
                data: [],
                selected: null,
                selectedDetails: [],
                selectedCurrentInfo: [],
                selectedCustomerData: [],
                selectedCustomerID: 'x',
                selectedIsReseller: false,
                selectedCustomerMessage: "",
                selectedVehicleData: [],
                selectedPshistoryData: [],
                selectedSelftestResult: [],
                selectedBuckets: [],
                selectedLifecycleStarttime: [{
                  "deviceName": "",
                  "lifecycleStart": "",
                  "deltaTCycleHours": "",
                  "dayssinceboot": "",
                  "hoursawake": "",
                  "durationDeepDeecharge": "",
                  "telemetryId": ""
                }],
                lifecycleDataLoading: true,
                deepDechargeDataLoading: true,
                pshistoryDataLoading: true,
                tableHeight: (window.innerHeight - 200),
                total: 0,
                q: '',
                qc: 'dname',
                telId: this.$route.params.id,
                loading: false,
                sortField: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                pageSize: 20,
                defaultOpenedDetails: [],
                abortController: new AbortController()
            }
        },
        methods: {
          shouldHighlightRed(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selectedDetails[0].maxcellvoltagered !== 0 && this.selectedDetails[0].mincellvoltagered !== 0) {
                return (numericValue <= this.selectedDetails[0].mincellvoltagered || numericValue >= this.selectedDetails[0].maxcellvoltagered);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selectedDetails[0].minvoltagered !== 0 && this.selectedDetails[0].maxvoltagered !== 0) {
                return (numericValue <= this.selectedDetails[0].minvoltagered || numericValue >= this.selectedDetails[0].maxvoltagered);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selectedDetails[0].pswarnredtemp > 0 && numericValue >= this.selectedDetails[0].pswarnredtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selectedDetails[0].tempred > 0 && numericValue >= this.selectedDetails[0].tempred);
            }
            return false; // Default: Do not highlight
          },

          shouldHighlightYellow(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selectedDetails[0].maxcellvoltageyellow !== 0 && this.selectedDetails[0].mincellvoltageyellow !== 0) {
                return (numericValue <= this.selectedDetails[0].mincellvoltageyellow || numericValue >= this.selectedDetails[0].maxcellvoltageyellow);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selectedDetails[0].minvoltageyellow !== 0 && this.selectedDetails[0].maxvoltageyellow !== 0) {
                return (numericValue <= this.selectedDetails[0].minvoltageyellow || numericValue >= this.selectedDetails[0].maxvoltageyellow);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selectedDetails[0].pswarnyellowtemp > 0 && numericValue >= this.selectedDetails[0].pswarnyellowtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selectedDetails[0].tempyellow > 0 && numericValue >= this.selectedDetails[0].tempyellow);
            }
            return false; // Default: Do not highlight
          },

            /*
            * Load async data
            */
            loadAsyncData(isSearch = false) {
                if (isSearch) {
                    this.page = 1; // Reset to first page when searching
                    console.log("isSearch: " + isSearch)
                }
                
                this.loading = true;
                this.$http.get(`/api/telemetrydata`, {
                    params: {
                        searchColumn: this.qc,
                        search: this.q,
                        page: this.page,
                        pageSize: this.pageSize,
                        draw: 0,
                        order: [this.sortField + ',' + this.sortOrder]
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        this.data = response.data.data;
                        this.total = response.data.recordsTotal;
                    } else {
                        this.data = [];
                        this.total = 0;
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.data = [];
                    this.total = 0;
                    this.loading = false;
                    throw error;
                });
            },
            /*
            * Load customer data
            */
            loadCustomerData() {
              console.log("loadCustomerData for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/customer/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedCustomerData = [response.data];
                      this.selectedCustomerID = response.data.id
                      console.log(this.selectedCustomerData)
                    } else {
                      this.selectedCustomerData = [];
                      this.selectedCustomerID = "x"
                    }
                  })
                  .catch((error) => {
                    this.selectedCustomerData = [];
                    this.selectedCustomerID = "x"
                    throw error
                  })
            },
            /*
             * Load vehicle data
            */
            loadVehicleData() {
              console.log("loadVehicleData for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/vehicle/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedVehicleData = [response.data];
                      console.log(this.selectedVehicleData)
                    } else {
                      this.selectedVehicleData = [];
                    }

                  })
                  .catch((error) => {
                    this.selectedVehicleData = [];
                    throw error
                  })
            },
            /*
             * Load vehicle data
            */

            loadPshistoryData() {
                this.pshistoryDataLoading = true;
                console.log("loadPshistoryData for telemetryID " + this.selectedDetails[0].id + " ...")

                this.$http.get(`/api/telemetrydata/pshistory/`+ this.selectedDetails[0].id, {
                    params: {
                        telemetryID: this.selectedDetails.id
                    }
                })
                .then((response) => {
                    console.log("History response status:", response.status);
                    console.log("Raw history data:", response.data);
                    
                    if (response.status === 200) {
                        if (response.data && response.data.length > 0) {
                            if (response.data[0].telemetrydataId === this.selectedDetails[0].id) {
                                this.selectedPshistoryData = response.data.map(item => ({
                                    ...item,
                                    formattedTime: item.time ? 
                                        new Date(item.time).toLocaleString('de-DE') : 
                                        this.formatDuration(item.ts)
                                }));
                                console.log("Processed history data:", this.selectedPshistoryData);
                            }
                        } else {
                            console.log("History data is empty");
                            this.selectedPshistoryData = [];
                        }
                    } else {
                        console.log("Unexpected response status");
                        this.selectedPshistoryData = [];
                    }
                    this.pshistoryDataLoading = false;
                })
                .catch((error) => {
                    console.error("Error loading history data:", error);
                    this.selectedPshistoryData = [];
                    this.pshistoryDataLoading = false;
                });
            },
            /*
             * Load vehicle data
            */
            loadSelftestResult() {
              console.log("loadSelftestResult for telemetryID " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/selftestresult/`+ this.selectedDetails[0].id, {
                params: {
                  telemetryID: this.selectedDetails.id
                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log(response.data)
                      this.selectedSelftestResult = [response.data];
                      console.log(this.selectedSelftestResult)
                    } else {
                      this.selectedSelftestResult = []
                    }

                  })
                  .catch((error) => {
                    this.selectedSelftestResult = [];
                    throw error
                  })
            },
            /*
             * Load lifecycle starttime data
            */
            loadLifecycleStarttime() {
              this.lifecycleDataLoading = true;

              console.log("loadLifecycleStarttime for deviceName " + this.selectedDetails[0].id + " ...")

              this.$http.get(`/api/telemetrydata/lifecycleStarttime/`+ this.selectedDetails[0].id, {
                params: {

                }
              })
                  .then((response) => {
                    console.log("response: " + response.status)
                    if (response.status === 200) {
                      console.log("lifecycleStarttime")
                      console.log(response.data)

                      if (response.data.deviceName === this.selectedDetails[0].dname) {
                        this.selectedLifecycleStarttime = [{
                          "deviceName": response.data.deviceName,
                          "lifecycleStart": response.data.lifecycleStart,
                          "deltaTCycleHours": this.selectedLifecycleStarttime[0].deltaTCycleHours,
                          "dayssinceboot": this.selectedLifecycleStarttime[0].dayssinceboot,
                          "hoursawake": this.selectedLifecycleStarttime[0].hoursawake,
                          "durationDeepDeecharge": this.selectedLifecycleStarttime[0].durationDeepDeecharge,
                          "telemetryId": this.selectedLifecycleStarttime[0].telemetrydataId,
                          "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                          "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
                        }]

                        console.log( this.selectedLifecycleStarttime);
                        this.lifecycleDataLoading = false;
                      }

                    } else {
                      this.selectedLifecycleStarttime.deviceName = "";
                      this.selectedLifecycleStarttime.lifecycleStart = "";
                    }
                  })
                  .catch((error) => {
                    this.selectedLifecycleStarttime.deviceName = "";
                    this.selectedLifecycleStarttime.lifecycleStart = "";
                    throw error
                  })
            },
          /*
             * Load lifecycle durationDeepDecharge data
            */
          loadDurationDeepDecharge() {
            this.deepDechargeDataLoading = true;

            console.log("loadDurationDeepDecharge for deviceName " + this.selectedDetails[0].id + " ...")

            this.$http.get(`/api/telemetrydata/durationDeepDecharge/`+ this.selectedDetails[0].id, {
              params: {

              }
            })
                .then((response) => {
                  console.log("response: " + response.status)
                  if (response.status === 200) {
                    console.log("durationDeepDecharge")
                    console.log(response.data)

                    if (response.data.telemetrydataId === this.selectedDetails[0].id) {
                      this.selectedLifecycleStarttime = [{
                        "deviceName": this.selectedLifecycleStarttime[0].deviceName,
                        "lifecycleStart": this.selectedLifecycleStarttime[0].lifecycleStart,
                        "deltaTCycleHours": this.selectedLifecycleStarttime[0].deltaTCycleHours,
                        "dayssinceboot": this.selectedLifecycleStarttime[0].dayssinceboot,
                        "hoursawake": this.selectedLifecycleStarttime[0].hoursawake,
                        "durationDeepDeecharge": response.data.durationDdDays,
                        "telemetryId": response.data.telemetrydataId,
                        "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                        "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
                      }]
                      console.log(this.selectedLifecycleStarttime);
                      this.deepDechargeDataLoading = false;
                    }

                  } else {
                    this.selectedLifecycleStarttime.durationDeepDeecharge = -1;
                    this.selectedLifecycleStarttime.telemetrydataId = -1;
                    this.deepDechargeDataLoading = false;
                  }
                })
                .catch((error) => {
                  this.selectedLifecycleStarttime.durationDeepDeecharge = -1;
                  this.selectedLifecycleStarttime.telemetrydataId = -1;
                  this.deepDechargeDataLoading = false;
                  throw error
                })
          },
          /*
           * convert a timestamp in seconds to absolute date, relative to a start date
           */
          convertTStoAbsouluteDateTimeString(start, ts) {
            const date = new Date(start);
            const newDate = date.setSeconds(date.getSeconds() + ts)
            console.log(newDate.toLocaleDateString('de-DE'));
            return newDate.toLocaleDateString('de-DE');
          },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page;
                this.loadAsyncData()

            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field;
                this.sortOrder = order;
                this.loadAsyncData()
            },
            /*
             * On resize event
             */
            onResize() {
              let offset = 200;
              self.tableHeight = (window.innerHeight - offset).toString() + 'px'
            },
            activateUser() {
              alert("activated")
            },
            closeAllOtherDetails(row) {
              this.defaultOpenedDetails = [row.id]
            },
            clickedDetails(row) {
              this.selectedDetails = [row]
              this.selectedLifecycleStarttime = [{
                "deviceName": "",
                "lifecycleStart": "",
                "deltaTCycleHours": this.selectedDetails[0].deltaTLifecycleHours,
                "dayssinceboot": this.selectedDetails[0].dayssinceboot,
                "hoursawake": this.selectedDetails[0].hoursawake,
                "durationDeepDeecharge": "",
                "telemetryId": "",
                "startCounter": this.selectedDetails[0].startCounter?? "n.v.",
                "chargeCounter": this.selectedDetails[0].chargeCounter?? "n.v."
              }]

              if (row && (row.socBuckets || row.tempBuckets)) {
                const calculatePercentagesWithHighlight = (buckets) => {
                  if (!buckets || !Array.isArray(buckets) || buckets.length === 0) return [];
                  
                  const numericBuckets = buckets.map(val => parseInt(val) || 0);
                  const total = numericBuckets.reduce((sum, count) => sum + count, 0);
                  
                  if (total === 0) return buckets.map(() => ({ value: '0.0%', highlight: 0 }));
                  
                  const percentages = numericBuckets.map(count => (count / total * 100).toFixed(1));
                  const maxVal = Math.max(...percentages);
                  
                  return percentages.map(val => ({
                    value: val + '%',
                    highlight: val > 0 ? 0.1 + (val / maxVal * 0.9) : 0 // Scale from 0.1 to 1.0 for visible values
                  }));
                };

                const socData = calculatePercentagesWithHighlight(row.socBuckets);
                const tempData = calculatePercentagesWithHighlight(row.tempBuckets);

                this.selectedBuckets = Array.from({ length: Math.max(socData.length, tempData.length) }, (_, i) => ({
                  socLabel: `${i*10}-${(i+1)*10}%`,
                  soc: socData[i]?.value || '0.0%',
                  socHighlight: socData[i]?.highlight || 0,
                  temp: tempData[i]?.value || '0.0%',
                  tempHighlight: tempData[i]?.highlight || 0,
                  tempLabel: `${i*10-20}-${(i+1)*10-20}°C`,
                  rawSoc: row.socBuckets?.[i] || 0,
                  rawTemp: row.tempBuckets?.[i] || 0
                }));
              } else {
                this.selectedBuckets = [];
              }

              console.log("raw buckets", JSON.stringify(row.tempBuckets));
              console.log("transformed buckets", JSON.stringify(this.selectedBuckets));
              this.selectedCustomerData = []
              this.selectedVehicleData = []
              this.selectedPshistoryData = [{"ts": "loading data..."}]
              this.selectedSelftestResult = []
              this.selectedCustomerMessage = row.customermessage ? row.customermessage : ""
              this.selectedCurrentInfo =
                  [(row.v1current !== 0 && row.v1min !== 0 && row.v1max !== 0 && row.v1current !== undefined && row.v1min !== undefined && row.v1max !== undefined)  && {"verticalHeader": "U cell1", "now": row.v1current +" V", "min": row.v1min +" V", "max": row.v1max +" V"},
                    (row.v2current !== 0 && row.v2min !== 0 && row.v2max !== 0 && row.v2current !== undefined && row.v2min !== undefined && row.v2max !== undefined)  && {"verticalHeader": "U cell2", "now": row.v2current +" V", "min": row.v2min +" V", "max": row.v2max +" V"},
                    (row.v3current !== 0 && row.v3min !== 0 && row.v3max !== 0 && row.v3current !== undefined && row.v3min !== undefined && row.v3max !== undefined)  && {"verticalHeader": "U cell3", "now": row.v3current +" V", "min": row.v3min +" V", "max": row.v3max +" V"},
                    (row.v4current !== 0 && row.v4min !== 0 && row.v4max !== 0 && row.v4current !== undefined && row.v4min !== undefined && row.v4max !== undefined)  && {"verticalHeader": "U cell4", "now": row.v4current +" V", "min": row.v4min +" V", "max": row.v4max +" V"},
                    (row.v5current !== 0 && row.v5min !== 0 && row.v5max !== 0 && row.v5current !== undefined && row.v5min !== undefined && row.v5max !== undefined)  && {"verticalHeader": "U cell5", "now": row.v5current +" V", "min": row.v5min +" V", "max": row.v5max +" V"},
                    (row.v6current !== 0 && row.v6min !== 0 && row.v6max !== 0 && row.v6current !== undefined && row.v6min !== undefined && row.v6max !== undefined)  && {"verticalHeader": "U cell6", "now": row.v6current +" V", "min": row.v6min +" V", "max": row.v6max +" V"},

                    (row.v7current !== 0 && row.v7min !== 0 && row.v7max !== 0 && row.v7current !== undefined && row.v7min !== undefined && row.v7max !== undefined)  && {"verticalHeader": "U cell7", "now": row.v7current +" V", "min": row.v7min +" V", "max": row.v7max +" V"},
                    (row.v8current !== 0 && row.v8min !== 0 && row.v8max !== 0 && row.v8current !== undefined && row.v8min !== undefined && row.v8max !== undefined) && {"verticalHeader": "U cell8", "now": row.v8current +" V", "min": row.v8min +" V", "max": row.v8max +" V"},
                    (row.v9current !== 0 && row.v9min !== 0 && row.v9max !== 0 && row.v9current !== undefined && row.v9min !== undefined && row.v9max !== undefined)  && {"verticalHeader": "U cell9", "now": row.v9current +" V", "min": row.v9min +" V", "max": row.v9max +" V"},
                    (row.v10current !== 0 && row.v10min !== 0 && row.v10max !== 0 && row.v10current !== undefined && row.v10min !== undefined && row.v10max !== undefined)  && {"verticalHeader": "U cell10", "now": row.v10current +" V", "min": row.v10min +" V", "max": row.v10max +" V"},
                    (row.v11current !== 0 && row.v11min !== 0 && row.v11max !== 0 && row.v11current !== undefined && row.v11min !== undefined && row.v11max !== undefined)  && {"verticalHeader": "U cell11", "now": row.v11current +" V", "min": row.v11min +" V", "max": row.v11max +" V"},
                    (row.v12current !== 0 && row.v12min !== 0 && row.v12max !== 0 && row.v12current !== undefined && row.v12min !== undefined && row.v12max !== undefined)  && {"verticalHeader": "U cell12", "now": row.v12current +" V", "min": row.v12min +" V", "max": row.v12max +" V"},
                    (row.v13current !== 0 && row.v13min !== 0 && row.v13max !== 0 && row.v13current !== undefined && row.v13min !== undefined && row.v13max !== undefined)  && {"verticalHeader": "U cell13", "now": row.v13current +" V", "min": row.v13min +" V", "max": row.v13max +" V"},
                    (row.v14current !== 0 && row.v14min !== 0 && row.v14max !== 0 && row.v14current !== undefined && row.v14min !== undefined && row.v14max !== undefined)  && {"verticalHeader": "U cell14", "now": row.v14current +" V", "min": row.v14min +" V", "max": row.v14max +" V"},
                    (row.v15current !== 0 && row.v15min !== 0 && row.v15max !== 0 && row.v15current !== undefined && row.v15min !== undefined && row.v15max !== undefined)  && {"verticalHeader": "U cell15", "now": row.v15current +" V", "min": row.v15min +" V", "max": row.v15max +" V"},
                    (row.v16current !== 0 && row.v16min !== 0 && row.v16max !== 0 && row.v16current !== undefined && row.v16min !== undefined && row.v16max !== undefined)  && {"verticalHeader": "U cell16", "now": row.v16current +" V", "min": row.v16min +" V", "max": row.v16max +" V"},

                    {"verticalHeader": "U total", "now": row.vcurrent +" V", "min": row.vmin +" V", "max": row.vmax +" V"},
                    {"verticalHeader": "T cells", "now": row.tcurrent +" °C", "min": row.tmin +" °C", "max": row.tmax +" °C"},
                    {"verticalHeader": "T pws", "now": row.pstemp +" °C", "min": row.pstempmin +" °C", "max": row.pstempmax +" °C"},
                    {"verticalHeader": "I total", "now": row.dcurrent +" A", "min": row.currentmin +" A", "max": row.currentmax +" A"}].filter(Boolean)
              this.loadCustomerData()
              this.loadVehicleData()
              this.loadLifecycleStarttime()
              this.loadDurationDeepDecharge()
              this.loadPshistoryData()
              this.loadSelftestResult()
            },
            formatDuration(seconds) {
                if (typeof seconds !== 'number') return '-';
                
                const days = Math.floor(seconds / (24 * 3600));
                const hours = Math.floor((seconds % (24 * 3600)) / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = Math.floor(seconds % 60);

                let parts = [];
                if (days > 0) parts.push(`${days}d`);
                if (hours > 0) parts.push(`${hours}h`);
                if (minutes > 0) parts.push(`${minutes}m`);
                if (remainingSeconds > 0) parts.push(`${remainingSeconds}s`);

                return parts.join(' ') || '0s';
            },
            formatJSON(data) {
                return JSON.stringify(data, null, 4);
            },
            copyToClipboard() {
                const jsonString = this.formatJSON(this.selected);
                navigator.clipboard.writeText(jsonString)
                    .then(() => {
                        this.$buefy.toast.open({
                            message: 'JSON copied to clipboard!',
                            type: 'is-success',
                            position: 'is-bottom',
                            duration: 2000
                        });
                    })
                    .catch(err => {
                        this.$buefy.toast.open({
                            message: 'Failed to copy JSON',
                            type: 'is-danger',
                            position: 'is-bottom',
                            duration: 2000
                        });
                        console.error('Failed to copy:', err);
                    });
            },

            downloadJSON() {
                const jsonString = this.formatJSON(this.selected);
                const blob = new Blob([jsonString], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const currentDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
                const filename = `${this.selected.dname}_ID-${this.selected.id}_${currentDate} LITEBLOX Telemetry Export.txt`;
                
                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                
                document.body.appendChild(link);
                link.click();
                
                // Cleanup
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
                
                this.$buefy.toast.open({
                    message: 'JSON file downloaded!',
                    type: 'is-success',
                    position: 'is-bottom',
                    duration: 2000
                });
            }
        },
        mounted() {
            this.$http.get(`/api/user/role`).then(({data}) => {
                this.roles = data;
                this.loadAsyncData();
            });
            console.log("telId: " + this.telId);
            let self = this
            let offset = 200
            // to update b-table max-height to have a freeze header (sticky-header makes fixed max-height only regardless the screen height)
            // placed a new issue in Git, see if we get any response.
            self.$nextTick(() => {
              window.addEventListener('resize', () => {
                // debugger
                self.tableHeight = (window.innerHeight - offset).toString() + 'px' // where offset is some kind of constant margin you need from the top
              })
            })
          },
      computed: {
        console: () => console,
        isReduced() {
          // Access the sidebar state from the parent AdminLayout component
          return this.$parent.isReduced;
        }
      },
        beforeDestroy() {
          window.removeEventListener('resize', () => {})
        },
    }
</script>

<style scoped>
 .table-wrapper td {
    vertical-align: middle;
  }

  .my-table.table-wrapper { overflow: auto !important; }

  .scrollableContent .message-body { overflow: auto !important; }


  .is-sticky-column-one {
    background-color: #b6aba1 !important;
    color: #000000 !important;
  }
  .is-sticky-column-two {
    background-color: #f1c725 !important;
    color: #000000 !important;
  }

  .no-paddding .message-body {
    padding: 0;
  }

  .no-margin message {
    margin-bottom: 0 !important;
  }

  .little-padding {
    padding: 0px 5px 5px;
  }

  .verticalHeader {
    background: #4daaea !important;
    color: black;
  }

  .custom-tooltip .tooltip {
    position: fixed !important;
    z-index: 9999;
  }

  .maxwidth {
    max-width: 250px;
  }

  .highlighted-value-red {
    color: red !important;
  }

  .highlighted-value-yellow {
    color: #ffc600 !important;
  }

  .label-color {
    color: #939393 !important;
  }


  pre {
      background-color: inherit;
      color: inherit;
      font-size: inherit;
      overflow-x: auto;
      padding: inherit;
      white-space: nowrap;
      word-wrap: normal;
  }
  .truncate {
    white-space: nowrap !important;
    /*overflow: hidden !important;*/
    text-overflow: ellipsis !important;
  }
  
.has-bucket-value {
    background-color: rgba(72, 199, 142, 0.1);
    font-weight: bold;
    padding: 0.2em 0.4em;
    border-radius: 3px;
}

.percentage-value {
  font-size: 0.8em;
  white-space: nowrap;
  padding: 0.2em 0.4em;
  border-radius: 3px;
}

.label-color {
  color: #939393 !important;
  font-size: 0.8em;
  white-space: nowrap;
}

.scrollable-container {
  position: fixed;
  top: -5px;
  left: 260px;
  right: 0;
  bottom: 0;
  overflow-x: auto;
  overflow-y: auto;
  transition: left 0.2s ease-out;
  z-index: 30;
}

/* Add this class for reduced sidebar */
.scrollable-container.is-reduced {
  left: 70px;
}

.content-wrapper {
  display: block;
  width: fit-content;
  min-width: 100%;
  height: auto;
  padding: 1rem;
}

/* Ensure columns don't wrap */
.content-wrapper .columns {
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Add this to ensure columns maintain their width */
.column {
  flex-shrink: 0;
}

/* Style the horizontal scrollbar */
.scrollable-container::-webkit-scrollbar {
  height: 8px;
}

.scrollable-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Handle collapsed sidebar */
@media screen and (max-width: 1023px) and (min-width: 769px) {
    .scrollable-container {
        left: 70px;
    }
}

/* Mobile screens - no sidebar */
@media screen and (max-width: 768px) {
    .scrollable-container {
        left: 0;
    }
}

.history-table {
  font-size: 0.9em;  /* Set font size */
  width: 100%;       /* Use full width of container */
  text-align: center !important;
}

.history-table >>> td,
.history-table >>> th {
  vertical-align: middle !important;
}

.json-viewer {
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 1rem;
  margin: 1rem;
  max-height: auto;
  overflow: auto;
  font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: left;
}

.json-viewer code {
  color: #333;
  background: none;
  padding: 0;
  display: block;
  text-align: left;
}

/* Remove any potential padding/margin from parent elements */
.json-viewer pre {
  margin: 0;
  text-align: left;
}

/* Style for different JSON elements */
.json-viewer .string { color: #22863a; }
.json-viewer .number { color: #005cc5; }
.json-viewer .boolean { color: #005cc5; }
.json-viewer .null { color: #808080; }
.json-viewer .key { color: #24292e; }

.export-controls {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.export-controls .button {
  margin-right: 0.5rem;
}

.telemetry-main-table {
  min-width: 200px;  /* Reduced minimum width */
}

.telemetry-main-table >>> td,
.telemetry-main-table >>> th {
  white-space: nowrap;
  padding: 0.25em 0.4em !important;
  font-size: 0.85em;
  text-align: left !important;  /* Force left alignment */
}

/* Ensure header cells are also left-aligned */
.telemetry-main-table >>> .header-left-align {
  text-align: left !important;
}

/* Make ID column narrower */
.telemetry-main-table >>> td.is-sticky-column-one,
.telemetry-main-table >>> td.is-sticky-column-two {
  padding: 0.25em 0.3em !important;
  min-width: 40px;
}

.lifecycle-table {
  min-width: 300px;  /* Adjust this value as needed */
}

.lifecycle-table >>> td {
  white-space: nowrap;  /* Prevent text wrapping in cells */
  padding: 0.25em 0.5em !important;  /* Reduce cell padding */
}

.lifecycle-table >>> th {
  padding: 0.25em 0.5em !important;  /* Reduce header padding */
  white-space: nowrap;
}

.buckets-table >>> td,
.buckets-table >>> th {
  white-space: nowrap;
  padding: 0.25em 0.5em !important;
}

/* Remove padding/margin from tabs */
::v-deep .lifecycle-tabs {
  margin: 0 !important;
}

::v-deep .lifecycle-tabs .tab-content {
  padding: 0 !important;
}

::v-deep .lifecycle-tabs .tabs {
  margin-bottom: 0 !important;
}

::v-deep .lifecycle-tabs .tab-item {
  padding: 0 !important;
}

/* Add capitalization for customer and vehicle info */
::v-deep .customer-info td,
::v-deep .vehicle-info td {
  text-transform: capitalize;
}

/* Exception for email addresses which should remain lowercase */
::v-deep .customer-info td[data-label="Email"] {
  text-transform: none;
}

/* Remove padding/margin from customer tabs */
::v-deep .customer-tabs {
  margin: 0 !important;
}

::v-deep .customer-tabs .tab-content {
  padding: 0 !important;
}

::v-deep .customer-tabs .tabs {
  margin-bottom: 0 !important;
}

::v-deep .customer-tabs .tab-item {
  padding: 0 !important;
}

/* Compact styling for device and current tables */
.device-table,
.current-table {
  min-width: 300px;  /* Adjust this value as needed */
}

.device-table >>> td,
.device-table >>> th,
.current-table >>> td,
.current-table >>> th {
  white-space: nowrap;
  padding: 0.25em 0.5em !important;
  font-size: 0.9em;
}

/* Ensure vertical header in current table stays visible */
.current-table >>> .verticalHeader {
  background: #4daaea !important;
  color: black;
  white-space: nowrap;
}

/* Make sure highlighted values stay properly formatted */
.highlighted-value-red,
.highlighted-value-yellow {
  white-space: nowrap;
  font-size: 0.9em;
}

/* Add responsive behavior for the history/selftest column */
@media screen and (min-width: 1216px) {  /* widescreen and up */
    .column.is-narrow-widescreen {
        flex: none;
        width: auto;
        overflow-x: auto;
    }
}

@media screen and (max-width: 1215px) {  /* below widescreen */
    .column.is-narrow-widescreen {
        flex: 1;
        width: 100%;
    }
}

.dashboard-section {
    min-width: min-content;
    width: 100%;
}

.dashboard-columns {
    flex-wrap: nowrap !important;
    width: 100%;
    gap: 0.5rem;
}

.history-column {
    flex: 1 1 auto !important;
    min-width: 350px;
    overflow-x: auto;
}

@media screen and (max-width: 1440px) {
    .dashboard-columns {
        flex-wrap: wrap !important;
    }
    
    .history-column {
        flex: 1 0 100% !important;
    }
}

/* Ensure tables don't force container width */
.history-column >>> table {
    width: 100%;
}

.selftest-table >>> td {
    padding: 0.2em 0.5em !important;
}

.selftest-table >>> td:first-child {
    width: 30px;
    text-align: center;
}

.selftest-table >>> td:last-child {
    padding-left: 0 !important;
}

.selftest-table {
    width: 200px;
}
</style>
