<template>
    <div>
        <div v-if="authorized && !isMobileView">
          <fab :actions="fabActions"
               :position="position"
               :bg-color="bgColor"
               :icon-size="iconSize"
               :main-icon="mainIcon"
               :main-tooltip="user.firstName + ' ' + user.lastName"
               @settings="showUser"
               @logout="logout"
          ></fab>
        </div>
      <NavBar v-if="authorized && isMobileView"></NavBar>

        <section class="section">
            <div class="container is-fluid">
                <router-view/>
            </div>
        </section>
    </div>
</template>

<style lang="scss">
</style>

<script>
    import NavBar from "./NavBar";
    import fab from "vue-fab";
    import {mapActions, mapGetters} from "vuex";

    export default {
        components: {fab, NavBar},
        data() {
            return {
                isMobileView: false,
                bgColor: '#f1c725',
                position: 'top-right',
                iconSize: 'small',
                mainIcon: 'person',
                fabActions: [
                    {
                        name: 'settings',
                        icon: 'settings',
                        color: '#4daaea'
                    },
                    {
                        name: 'logout',
                        icon: 'logout',
                        color: "#d70202"
                    }
                ]
            }
        },
        beforeDestroy () {
          if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.onResize, { passive: true })
          }
        },
        mounted () {
          this.onResize()
          window.addEventListener('resize', this.onResize, { passive: true })
        },
        methods: {
            ...mapGetters('user', ['isAuthorized', 'isAdmin', 'getUser']),
            ...mapActions('user', ['setUser']),
            showUser() {
                this.$router.push('admin/profile').catch(()=>{})
            },
            logout: function () {
                this.$http.get('/signOut')
                this.setUser({})
                this.$router.push('/')
            },
            onResize () {
              this.isMobileView = window.innerWidth < 769
            }
        },
        computed: {
            authorized: function() {
                return this.isAuthorized();
            },
            user: function() {
                return this.getUser();
            }
        }

    }
</script>