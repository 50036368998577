<template>
    <header class="card-header">
        <div class="card-header-title">{{label}}</div>
    </header>
</template>

<script>
    export default {
        name: 'CardHeaderWithProviderButtons',
        components: {},
        props: ['label', 'redirectTo'],
    }
</script>