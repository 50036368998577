<template>
    <router-view/>
</template>

<style lang="scss">
    @import "~bulma/sass/utilities/_all";
    $primary: #2196f3;
    $medium-dark: #949494;
    $primary-light: #7695bf;
    $danger: #e02f3a;
    $primary-invert: $primary/*findColorInvert($primary)*/;
    $colors: (
            "white": ($white, $black),
            "black": ($black, $white),
            "light": ($light, $light-invert),
            "medium-dark": ($medium-dark, $light),
            "dark": ($dark, $dark-invert),
            "primary": ($primary, $primary-invert),
            "primary-light": ($primary-light, $light),
            "info": ($info, $info-invert),
            "success": ($success, $success-invert),
            "warning": ($warning, $warning-invert),
            "danger": ($danger, $danger-invert),

    );
    $link: $primary;
    $link-invert: $primary-invert;
    $link-focus-border: $primary;



    // Import Bulma and Buefy styles
    @import "~bulma";
    @import "~buefy/src/scss/buefy";
</style>

<script>
    import {mapActions} from 'vuex'

    export default {
        methods: {
            ...mapActions('csrf', ['setToken']),
        },
        created: function () {
            const bodyHtmlTag = document.getElementsByTagName("body")[0];
            const csrfToken = bodyHtmlTag.getAttribute('csrf-token-value');
            this.setToken(csrfToken);
        },
    }
</script>