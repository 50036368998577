<template>
    <section>

<!--      <b-input placeholder="...on column"
               type="search"
               icon-pack="fas"
               v-model="qc"
               @input="loadAsyncData()"
               icon="search">
      </b-input>-->

        <b-tabs>
            <b-tab-item label="Datasets">

                <b-input placeholder="Search..."
                         type="search"
                         icon-pack="fas"
                         v-model="q"
                         @input="loadAsyncData()"
                         icon="search">
                </b-input>

                <b-table
                        :data="data"
                        ref="table"
                        :loading="loading"
                        class="my-table"
                        :striped="true"
                        :hoverable="true"
                        :selected.sync="selected"
                        :debounce-search="1000"
                        :sticky-header="true"
                        :height="tableHeight"
                        @resize="onResize"
                        focusable
                        :opened-detailed="defaultOpenedDetails"
                        detailed
                        detail-key="id"
                        @details-open="(row) => closeAllOtherDetails(row)"
                        :show-detail-icon="false"

                        narrowed
                        paginated
                        backend-pagination
                        :total="total"
                        :per-page="pageSize"
                        @page-change="onPageChange"
                        aria-next-label="Next page"
                        aria-previous-label="Previous page"
                        aria-page-label="Page"
                        aria-current-label="Current page"
                        backend-sorting
                        :default-sort-direction="defaultSortOrder"
                        :default-sort="[sortField, sortOrder]"
                        @sort="onSort">

                  <b-table-column field="id" label="ID" v-slot="props" width="40" sticky numeric sortable class="is-sticky-column-one">
                    <b-tooltip v-if="props.row.sentactive" :label="'service request'" position="is-right">
                      <span class="tag is-success is-status is-small is-sticky-column-two">{{ props.row.id }}</span>
                      <span style="font-size: 0.5em; color: #ff99aa;" class="is-small">service req.</span>
                    </b-tooltip>
                    <span v-else class="tag is-success is-status is-small is-sticky-column-one">{{ props.row.id }}</span>
                  </b-table-column>

                    <b-table-column field="createdAt" label="Date" sortable centered v-slot="props">
                        {{ new Date(props.row.createdAt).toLocaleDateString('de-DE')}}
                    </b-table-column>

                    <b-table-column field="createdAt" label="Time" centered v-slot="props">
                        {{ new Date(props.row.createdAt).toLocaleTimeString('de-DE')}}
                    </b-table-column>

                    <b-table-column field="dname" label="Device Name" sortable centered v-slot="props">
                        <pre>{{ props.row.dname }}</pre>
                    </b-table-column>

                    <b-table-column field="sentactive" label="Support Request" sortable centered v-slot="props">
                        {{ props.row.sentactive }}
                    </b-table-column>

                    <b-table-column field="email" label="Email" sortable centered v-slot="props">
                        {{ props.row.email }}
                    </b-table-column>

                    <b-table-column field="firmwareversion" label="FW Version" sortable centered v-slot="props">
                        {{ props.row.firmwareversion }}
                    </b-table-column>

                    <b-table-column field="firmwarevariante" label="FW Variation" sortable centered v-slot="props">
                        {{ props.row.firmwarevariante }}
                    </b-table-column>

                    <b-table-column field="dtype" label="Type" centered v-slot="props">
                        {{ props.row.dtype }}
                    </b-table-column>

                    <b-table-column field="dserial" label="Serial Akkudoc" sortable centered v-slot="props">
                      {{ props.row.dserial }}
                    </b-table-column>

                    <b-table-column field="revision" label="Revision" centered v-slot="props">
                        {{ props.row.revision }}
                    </b-table-column>

                    <b-table-column field="dstatus" label="Status" centered v-slot="props">
                        {{ props.row.dstatus }}
                    </b-table-column>

                    <b-table-column field="statusint" label="Status Int" centered v-slot="props">
                        {{ props.row.statusint }}
                    </b-table-column>

                    <b-table-column field="reason" label="Reason" centered v-slot="props">
                        {{ props.row.reason }}
                    </b-table-column>

                    <b-table-column field="reasonint" label="Reason Int" centered v-slot="props">
                        {{ props.row.reasonint }}
                    </b-table-column>

                    <b-table-column field="killswitchenabled" label="IKOS" centered v-slot="props">
                        {{ props.row.killswitchenabled }}
                    </b-table-column>

                    <b-table-column field="customermessage" label="Customer Message" centered text width="80" v-slot="props">
                        <b-tooltip :label="props.row.customermessage" position="is-left">
                            <a @click="props.toggleDetails(props.row)">
                                {{ props.row.customermessage.toString().substr(0, 10) }}...
                            </a>
                        </b-tooltip>
                    </b-table-column>

                    <b-table-column field="dpassword" label="Password" centered v-slot="props">
                        {{ props.row.dpassword }}
                    </b-table-column>

                    <b-table-column field="resetEeprom" label="Reset EEprom" centered v-slot="props">
                        {{ props.row.resetEeprom }}
                    </b-table-column>

                    <b-table-column field="dpassword" label="Password" centered v-slot="props">
                        {{ props.row.dpassword }}
                    </b-table-column>

                    <b-table-column field="vcurrent" label="V current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U total', 'now', props.row.vcurrent) && !shouldHighlightRed('U total', 'now', props.row.vcurrent),
  'highlighted-value-red': shouldHighlightRed('U total', 'now', props.row.vcurrent)
}">
                        {{ props.row.vcurrent }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v1current" label="V1 current" centered v-slot="props">
                      <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v1current) && !shouldHighlightRed('U cell', 'now', props.row.v1current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v1current)
}">
                        {{ props.row.v1current }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v2current" label="V2 current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v2current) && !shouldHighlightRed('U cell', 'now', props.row.v2current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v2current)
}">
                        {{ props.row.v2current }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v3current" label="V3 current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v3current) && !shouldHighlightRed('U cell', 'now', props.row.v3current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v3current)
}">
                        {{ props.row.v3current }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v4current" label="V4 current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v4current) && !shouldHighlightRed('U cell', 'now', props.row.v4current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v4current)
}">
                        {{ props.row.v4current }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v5current" label="V5 current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v5current) && !shouldHighlightRed('U cell', 'now', props.row.v5current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v5current)
}">
                        {{ props.row.v5current }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v6current" label="V6 current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v6current) && !shouldHighlightRed('U cell', 'now', props.row.v6current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v6current)
}">
                        {{ props.row.v6current }}
                        </span>
                    </b-table-column>

                  <b-table-column field="v7current" label="V7 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v7current) && !shouldHighlightRed('U cell', 'now', props.row.v7current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v7current)
}">
                        {{ props.row.v7current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v8current" label="V8 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v8current) && !shouldHighlightRed('U cell', 'now', props.row.v8current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v8current)
}">
                        {{ props.row.v8current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v9current" label="V9 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v9current) && !shouldHighlightRed('U cell', 'now', props.row.v9current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v9current)
}">
                        {{ props.row.v9current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v10current" label="V10 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v10current) && !shouldHighlightRed('U cell', 'now', props.row.v10current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v10current)
}">
                        {{ props.row.v10current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v11current" label="V11 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v11current) && !shouldHighlightRed('U cell', 'now', props.row.v11current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v11current)
}">
                        {{ props.row.v11current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v12current" label="V12 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v12current) && !shouldHighlightRed('U cell', 'now', props.row.v12current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v12current)
}">
                        {{ props.row.v12current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v13current" label="V13 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v13current) && !shouldHighlightRed('U cell', 'now', props.row.v13current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v13current)
}">
                        {{ props.row.v13current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v14current" label="V14 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v14current) && !shouldHighlightRed('U cell', 'now', props.row.v14current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v14current)
}">
                        {{ props.row.v14current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v15current" label="V15 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v15current) && !shouldHighlightRed('U cell', 'now', props.row.v15current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v15current)
}">
                        {{ props.row.v15current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v16current" label="V16 current" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v16current) && !shouldHighlightRed('U cell', 'now', props.row.v16current),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v16current)
}">
                        {{ props.row.v16current }}
                        </span>
                  </b-table-column>

                  <b-table-column field="vcurrentunfiltered" label="V current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U total', 'now', props.row.vcurrentunfiltered) && !shouldHighlightRed('U total', 'now', props.row.vcurrentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U total', 'now', props.row.vcurrentunfiltered)
}">
                        {{ props.row.vcurrentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v1currentunfiltered" label="V1 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v1currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v1currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v1currentunfiltered)
}">
                        {{ props.row.v1currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v2currentunfiltered" label="V2 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v2currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v2currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v2currentunfiltered)
}">
                        {{ props.row.v2currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v3currentunfiltered" label="V3 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v3currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v3currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v3currentunfiltered)
}">
                        {{ props.row.v3currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v4currentunfiltered" label="V4 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v4currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v4currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v4currentunfiltered)
}">
                        {{ props.row.v4currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v5currentunfiltered" label="V5 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v5currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v5currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v5currentunfiltered)
}">
                        {{ props.row.v5currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v6currentunfiltered" label="V6 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v6currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v6currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v6currentunfiltered)
}">
                        {{ props.row.v6currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v7currentunfiltered" label="V7 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v7currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v7currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v7currentunfiltered)
}">
                        {{ props.row.v7currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v8currentunfiltered" label="V8 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v8currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v8currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v8currentunfiltered)
}">
                        {{ props.row.v8currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v9currentunfiltered" label="V9 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v9currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v9currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v9currentunfiltered)
}">
                        {{ props.row.v9currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v10currentunfiltered" label="V10 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v10currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v10currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v10currentunfiltered)
}">
                        {{ props.row.v10currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v11currentunfiltered" label="V11 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v11currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v11currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v11currentunfiltered)
}">
                        {{ props.row.v11currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v12currentunfiltered" label="V12 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v12currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v12currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v12currentunfiltered)
}">
                        {{ props.row.v12currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v13currentunfiltered" label="V13 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v13currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v13currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v13currentunfiltered)
}">
                        {{ props.row.v13currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v14currentunfiltered" label="V14 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v14currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v14currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v14currentunfiltered)
}">
                        {{ props.row.v14currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v15currentunfiltered" label="V15 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v15currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v15currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v15currentunfiltered)
}">
                        {{ props.row.v15currentunfiltered }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v16currentunfiltered" label="V16 current unfiltered" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'now', props.row.v16currentunfiltered) && !shouldHighlightRed('U cell', 'now', props.row.v16currentunfiltered),
  'highlighted-value-red': shouldHighlightRed('U cell', 'now', props.row.v16currentunfiltered)
}">
                        {{ props.row.v16currentunfiltered }}
                        </span>
                  </b-table-column>



                    <b-table-column field="vmin" label="V min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U total', 'min', props.row.vmin) && !shouldHighlightRed('U total', 'min', props.row.vmin),
  'highlighted-value-red': shouldHighlightRed('U total', 'min', props.row.vmin)
}">
                        {{ props.row.vmin }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v1min" label="V1 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v1min) && !shouldHighlightRed('U cell', 'min', props.row.v1min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v1min)
}">
                        {{ props.row.v1min }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v2min" label="V2 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v2min) && !shouldHighlightRed('U cell', 'min', props.row.v2min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v2min)
}">
                        {{ props.row.v2min }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v3min" label="V3 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v3min) && !shouldHighlightRed('U cell', 'min', props.row.v3min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v3min)
}">
                        {{ props.row.v3min }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v4min" label="V4 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v4min) && !shouldHighlightRed('U cell', 'min', props.row.v4min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v4min)
}">
                        {{ props.row.v4min }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v5min" label="V5 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v5min) && !shouldHighlightRed('U cell', 'min', props.row.v5min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v5min)
}">
                        {{ props.row.v5min }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v6min" label="V6 min" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v6min) && !shouldHighlightRed('U cell', 'min', props.row.v6min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v6min)
}">
                        {{ props.row.v6min }}
                        </span>
                    </b-table-column>
                  <b-table-column field="v7min" label="V7 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v7min) && !shouldHighlightRed('U cell', 'min', props.row.v7min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v7min)
}">
                        {{ props.row.v7min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v8min" label="V8 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v8min) && !shouldHighlightRed('U cell', 'min', props.row.v8min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v8min)
}">
                        {{ props.row.v8min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v9min" label="V9 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v9min) && !shouldHighlightRed('U cell', 'min', props.row.v9min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v9min)
}">
                        {{ props.row.v9min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v10min" label="V10 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v10min) && !shouldHighlightRed('U cell', 'min', props.row.v10min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v10min)
}">
                        {{ props.row.v10min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v11min" label="V11 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v11min) && !shouldHighlightRed('U cell', 'min', props.row.v11min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v11min)
}">
                        {{ props.row.v11min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v12min" label="V12 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v12min) && !shouldHighlightRed('U cell', 'min', props.row.v12min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v12min)
}">
                        {{ props.row.v12min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v13min" label="V13 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v13min) && !shouldHighlightRed('U cell', 'min', props.row.v13min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v13min)
}">
                        {{ props.row.v13min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v14min" label="V14 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v14min) && !shouldHighlightRed('U cell', 'min', props.row.v14min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v14min)
}">
                        {{ props.row.v14min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v15min" label="V15 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v15min) && !shouldHighlightRed('U cell', 'min', props.row.v15min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v15min)
}">
                        {{ props.row.v15min }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v16min" label="V16 min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'min', props.row.v16min) && !shouldHighlightRed('U cell', 'min', props.row.v16min),
  'highlighted-value-red': shouldHighlightRed('U cell', 'min', props.row.v16min)
}">
                        {{ props.row.v16min }}
                        </span>
                  </b-table-column>


                    <b-table-column field="vmax" label="V max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U total', 'max', props.row.vmax) && !shouldHighlightRed('U total', 'max', props.row.vmax),
  'highlighted-value-red': shouldHighlightRed('U total', 'max', props.row.vmax)
}">
                        {{ props.row.vmax }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v1max" label="V1 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v1max) && !shouldHighlightRed('U cell', 'max', props.row.v1max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v1max)
}">
                        {{ props.row.v1max }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v2max" label="V2 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v2max) && !shouldHighlightRed('U cell', 'max', props.row.v2max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v2max)
}">
                        {{ props.row.v2max }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v3max" label="V3 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v3max) && !shouldHighlightRed('U cell', 'max', props.row.v3max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v3max)
}">
                        {{ props.row.v3max }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v4max" label="V4 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v4max) && !shouldHighlightRed('U cell', 'max', props.row.v4max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v4max)
}">
                        {{ props.row.v4max }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v5max" label="V5 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v5max) && !shouldHighlightRed('U cell', 'max', props.row.v5max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v5max)
}">
                        {{ props.row.v5max }}
                        </span>
                    </b-table-column>

                    <b-table-column field="v6max" label="V6 max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v6max) && !shouldHighlightRed('U cell', 'max', props.row.v6max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v6max)
}">
                        {{ props.row.v6max }}
                        </span>
                    </b-table-column>

                  <b-table-column field="v7max" label="V7 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v7max) && !shouldHighlightRed('U cell', 'max', props.row.v7max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v7max)
}">
                        {{ props.row.v7max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v8max" label="V8 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v8max) && !shouldHighlightRed('U cell', 'max', props.row.v8max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v8max)
}">
                        {{ props.row.v8max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v9max" label="V9 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v9max) && !shouldHighlightRed('U cell', 'max', props.row.v9max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v9max)
}">
                        {{ props.row.v9max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v10max" label="V10 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v10max) && !shouldHighlightRed('U cell', 'max', props.row.v10max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v10max)
}">
                        {{ props.row.v10max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v11max" label="V11 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v11max) && !shouldHighlightRed('U cell', 'max', props.row.v11max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v11max)
}">
                        {{ props.row.v11max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v12max" label="V12 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v12max) && !shouldHighlightRed('U cell', 'max', props.row.v12max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v12max)
}">
                        {{ props.row.v12max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v13max" label="V13 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v13max) && !shouldHighlightRed('U cell', 'max', props.row.v13max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v13max)
}">
                        {{ props.row.v13max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v14max" label="V14 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v14max) && !shouldHighlightRed('U cell', 'max', props.row.v14max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v14max)
}">
                        {{ props.row.v14max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v15max" label="V15 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v15max) && !shouldHighlightRed('U cell', 'max', props.row.v15max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v15max)
}">
                        {{ props.row.v15max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="v16max" label="V16 max" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('U cell', 'max', props.row.v16max) && !shouldHighlightRed('U cell', 'max', props.row.v16max),
  'highlighted-value-red': shouldHighlightRed('U cell', 'max', props.row.v16max)
}">
                        {{ props.row.v16max }}
                        </span>
                  </b-table-column>

                  <b-table-column field="tcurrent" label="T current" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T cells', 'now', props.row.tcurrent) && !shouldHighlightRed('T cells', 'now', props.row.tcurrent),
  'highlighted-value-red': shouldHighlightRed('T cells', 'now', props.row.tcurrent)
}">
                        {{ props.row.tcurrent }}
                        </span>
                  </b-table-column>

                  <b-table-column field="tmin" label="T min" centered v-slot="props">
                    <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T cells', 'min', props.row.tmin) && !shouldHighlightRed('T cells', 'min', props.row.tmin),
  'highlighted-value-red': shouldHighlightRed('T cells', 'min', props.row.tmin)
}">
                        {{ props.row.tmin }}
                        </span>
                  </b-table-column>

                    <b-table-column field="tmax" label="T max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T cells', 'max', props.row.tmax) && !shouldHighlightRed('T cells', 'max', props.row.tmax),
  'highlighted-value-red': shouldHighlightRed('T cells', 'max', props.row.tmax)
}">
                        {{ props.row.tmax }}
                        </span>
                    </b-table-column>

                  <b-table-column field="tempBuckets" label="Temp Buckets" centered text width="80" v-slot="props">
                    <b-tooltip :label="props.row.tempBuckets" position="is-left">
                      <a v-if="props.row.tempBuckets">
                        {{ props.row.tempBuckets.toString().substr(0, 10) }}...
                      </a>
                      <a v-else>
                        {{ }}
                      </a>
                    </b-tooltip>
                  </b-table-column>

                    <b-table-column field="pstemp" label="PS Temp" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T pws', 'now', props.row.pstemp) && !shouldHighlightRed('T pws', 'now', props.row.pstemp),
  'highlighted-value-red': shouldHighlightRed('T pws', 'now', props.row.pstemp)
}">
                        {{ props.row.pstemp }}
                        </span>
                    </b-table-column>

                    <b-table-column field="pstempmax" label="PS Temp max" centered v-slot="props">
                        <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T pws', 'max', props.row.pstempmax) && !shouldHighlightRed('T pws', 'max', props.row.pstempmax),
  'highlighted-value-red': shouldHighlightRed('T pws', 'max', props.row.pstempmax)
}">
                        {{ props.row.pstempmax }}
                        </span>
                    </b-table-column>

                    <b-table-column field="pstempmin" label="PS Temp min" centered v-slot="props">
                      <span :class="{
  'highlighted-value-yellow': shouldHighlightYellow('T pws', 'min', props.row.pstempmin) && !shouldHighlightRed('T pws', 'min', props.row.pstempmin),
  'highlighted-value-red': shouldHighlightRed('T pws', 'min', props.row.pstempmin)
}">
                        {{ props.row.pstempmin }}
                        </span>
                    </b-table-column>

                    <b-table-column field="pscurrent" label="PS current" centered v-slot="props">
                        {{ props.row.pscurrent }}
                    </b-table-column>

                    <b-table-column field="pscurrentmin" label="PS current min" centered v-slot="props">
                        {{ props.row.pscurrentmin }}
                    </b-table-column>

                    <b-table-column field="pscurrentmax" label="PS current max" centered v-slot="props">
                        {{ props.row.pscurrentmax }}
                    </b-table-column>

                    <b-table-column field="pscurrentcorrection" label="PS current Correction" centered v-slot="props">
                        {{ props.row.pscurrentcorrection }}
                    </b-table-column>

                    <b-table-column field="psmaxtemp" label="PS max Temp" centered v-slot="props">
                        {{ props.row.psmaxtemp }}
                    </b-table-column>

                    <b-table-column field="pswarnyellowtemp" label="PS Warn Temp yellow" centered v-slot="props">
                        {{ props.row.pswarnyellowtemp }}
                    </b-table-column>

                    <b-table-column field="pswarnredtemp" label="PS Warn Temp red" centered v-slot="props">
                        {{ props.row.pswarnredtemp }}
                    </b-table-column>

                    <b-table-column field="psmaxcurrent" label="PS max current" centered v-slot="props">
                        {{ props.row.psmaxcurrent }}
                    </b-table-column>

                    <b-table-column field="psantitheftcurrent" label="PS Antitheft current" centered v-slot="props">
                        {{ props.row.psantitheftcurrent }}
                    </b-table-column>

                    <b-table-column field="psantitheftenabled" label="PS Antitheft enabled" centered v-slot="props">
                        {{ props.row.psantitheftenabled }}
                    </b-table-column>

                    <b-table-column field="psresistance" label="PS resistance" centered v-slot="props">
                        {{ props.row.psresistance }}
                    </b-table-column>

                    <b-table-column field="psexternalinterfaceenabled" label="PS Interface enabled" centered v-slot="props">
                        {{ props.row.psexternalinterfaceenabled }}
                    </b-table-column>

                    <b-table-column field="psswitchhistorypos" label="PS History pos" centered v-slot="props">
                        {{ props.row.psswitchhistorypos }}
                    </b-table-column>

                    <b-table-column field="psresetEeprom" label="PS Reset EEprom" centered v-slot="props">
                        {{ props.row.psresetEeprom }}
                    </b-table-column>

                    <b-table-column field="psovercurrenttimeout" label="PS Overcurrent Timeout" centered v-slot="props">
                        {{ props.row.psovercurrenttimeout }}
                    </b-table-column>

                    <b-table-column field="psvoltagemin" label="PS V min" centered v-slot="props">
                        {{ props.row.psvoltagemin }}
                    </b-table-column>

                  <b-table-column field="numcells" label="Number Cells" centered v-slot="props">
                    {{ props.row.numcells }}
                  </b-table-column>

                    <b-table-column field="cellsenabled" label="Cells enabled" centered v-slot="props">
                        {{ props.row.cellsenabled }}
                    </b-table-column>

                    <b-table-column field="cellbalancing" label="Cell Balancing" centered v-slot="props">
                        {{ props.row.cellbalancing }}
                    </b-table-column>

                    <b-table-column field="cellov" label="Cell OV" centered v-slot="props">
                        {{ props.row.cellov }}
                    </b-table-column>

                    <b-table-column field="celluv" label="Cell UV" centered v-slot="props">
                        {{ props.row.celluv }}
                    </b-table-column>

                    <b-table-column field="killswitchpolarity" label="Killswitch Polarity" centered v-slot="props">
                        {{ props.row.killswitchpolarity }}
                    </b-table-column>

                    <b-table-column field="killswitchdefault" label="Killswitch Default" centered v-slot="props">
                        {{ props.row.killswitchdefault }}
                    </b-table-column>

                    <b-table-column field="dcurrent" label="Current" centered v-slot="props">
                        {{ props.row.dcurrent }}
                    </b-table-column>

                    <b-table-column field="currentmin" label="Current min" centered v-slot="props">
                        {{ props.row.currentmin }}
                    </b-table-column>

                    <b-table-column field="currentmax" label="Current max" centered v-slot="props">
                        {{ props.row.currentmax }}
                    </b-table-column>

                    <b-table-column field="standbycurrent" label="Standby Current" centered v-slot="props">
                        {{ props.row.standbycurrent }}
                    </b-table-column>

                    <b-table-column field="standbyvoltage" label="Standby Voltage" centered v-slot="props">
                        {{ props.row.standbyvoltage }}
                    </b-table-column>

                  <b-table-column field="shutdownvoltage" label="Shutdown Voltage" centered v-slot="props">
                    {{ props.row.shutdownvoltage }}
                  </b-table-column>

                  <b-table-column field="shutdownvoltagetime" label="Shutdown Voltage Time" centered v-slot="props">
                    {{ props.row.shutdownvoltagetime }}
                  </b-table-column>

                    <b-table-column field="balancingminvoltage" label="Balancing V min" centered v-slot="props">
                        {{ props.row.balancingminvoltage }}
                    </b-table-column>

                    <b-table-column field="balancingmaxdifference" label="Balancing max Diff" centered v-slot="props">
                        {{ props.row.balancingmaxdifference }}
                    </b-table-column>

                    <b-table-column field="tempyellow" label="Temp yellow" centered v-slot="props">
                        {{ props.row.tempyellow }}
                    </b-table-column>

                    <b-table-column field="tempred" label="Temp red" centered v-slot="props">
                        {{ props.row.tempred }}
                    </b-table-column>

                    <b-table-column field="tempmax" label="Temp max" centered v-slot="props">
                        {{ props.row.tempmax }}
                    </b-table-column>

                    <b-table-column field="mincellvoltage" label="Cell V min" centered v-slot="props">
                        {{ props.row.mincellvoltage }}
                    </b-table-column>

                    <b-table-column field="mincellvoltageyellow" label="Cell V min yellow" centered v-slot="props">
                        {{ props.row.mincellvoltageyellow }}
                    </b-table-column>

                    <b-table-column field="mincellvoltagered" label="Cell V min red" centered v-slot="props">
                        {{ props.row.mincellvoltagered }}
                    </b-table-column>

                    <b-table-column field="maxcellvoltage" label="Cell V max" centered v-slot="props">
                        {{ props.row.maxcellvoltage }}
                    </b-table-column>

                    <b-table-column field="maxcellvoltageyellow" label="Cell V max yellow" centered v-slot="props">
                        {{ props.row.maxcellvoltageyellow }}
                    </b-table-column>

                    <b-table-column field="maxcellvoltagered" label="Cell V max red" centered v-slot="props">
                        {{ props.row.maxcellvoltagered }}
                    </b-table-column>

                    <b-table-column field="maxvoltage" label="V max" centered v-slot="props">
                        {{ props.row.maxvoltage }}
                    </b-table-column>

                    <b-table-column field="maxvoltageyellow" label="V max yellow" centered v-slot="props">
                        {{ props.row.maxvoltageyellow }}
                    </b-table-column>

                    <b-table-column field="maxvoltagered" label="V max red" centered v-slot="props">
                        {{ props.row.maxvoltagered }}
                    </b-table-column>

                    <b-table-column field="minvoltage" label="V min" centered v-slot="props">
                        {{ props.row.minvoltage }}
                    </b-table-column>

                    <b-table-column field="minvoltageyellow" label="V min yellow" centered v-slot="props">
                        {{ props.row.minvoltageyellow }}
                    </b-table-column>

                    <b-table-column field="minvoltagered" label="V min red" centered v-slot="props">
                        {{ props.row.minvoltagered }}
                    </b-table-column>

                    <b-table-column field="maxchargecurrent" label="Charge C max" centered v-slot="props">
                        {{ props.row.maxchargecurrent }}
                    </b-table-column>

                    <b-table-column field="maxdischargecurrent" label="Discharge C max" centered v-slot="props">
                        {{ props.row.maxdischargecurrent }}
                    </b-table-column>

                    <b-table-column field="overcurrenttimeout" label="OC Timeout" centered v-slot="props">
                        {{ props.row.overcurrenttimeout }}
                    </b-table-column>

                    <b-table-column field="filterfactor" label="Filter Factor" centered v-slot="props">
                        {{ props.row.filterfactor }}
                    </b-table-column>

                    <b-table-column field="errorpermanence" label="Error Permanence" centered v-slot="props">
                        {{ props.row.errorpermanence }}
                    </b-table-column>

                    <b-table-column field="softclose" label="Soft Close" centered v-slot="props">
                        {{ props.row.softclose }}
                    </b-table-column>

                    <b-table-column field="capacity" label="Capacity" centered v-slot="props">
                        {{ props.row.capacity }}
                    </b-table-column>

                    <b-table-column field="voltagefull" label="V full" centered v-slot="props">
                        {{ props.row.voltagefull }}
                    </b-table-column>

                  <b-table-column field="startCounter" label="Start counter" centered v-slot="props">
                    {{ props.row.startCounter }}
                  </b-table-column>

                    <b-table-column field="soc" label="SOC" centered v-slot="props">
                        {{ props.row.soc }}
                    </b-table-column>

                  <b-table-column field="socbuckets" label="SOC Buckets" centered text width="80" v-slot="props">
                    <b-tooltip :label="props.row.socBuckets" position="is-left">
                      <a v-if="props.row.socBuckets">
                        {{ props.row.socBuckets.toString().substr(0, 10) }}...
                      </a>
                      <a v-else>
                        {{ }}
                      </a>
                    </b-tooltip>
                  </b-table-column>

                    <b-table-column field="eremain" label="E Remain" centered v-slot="props">
                        {{ props.row.eremain }}
                    </b-table-column>

                    <b-table-column field="beeperOn" label="Beeper On" centered v-slot="props">
                        {{ props.row.beeperOn }}
                    </b-table-column>

                    <b-table-column field="resettofull" label="Reset to Full" centered v-slot="props">
                        {{ props.row.resettofull }}
                    </b-table-column>

                    <b-table-column field="resetstatistics" label="Reset Statistics" centered v-slot="props">
                        {{ props.row.resetstatistics }}
                    </b-table-column>

                    <b-table-column field="hasnewpowerswitch" label="New PS" centered v-slot="props">
                        {{ props.row.hasnewpowerswitch }}
                    </b-table-column>

                    <b-table-column field="useroutpolarity" label="Polarity User Out" centered v-slot="props">
                        {{ props.row.useroutpolarity }}
                    </b-table-column>

                    <b-table-column field="recordstatus" label="Record Status" centered v-slot="props">
                        {{ props.row.recordstatus }}
                    </b-table-column>

                    <b-table-column field="recordstatuswrite" label="Record Status Write" centered v-slot="props">
                        {{ props.row.recordstatuswrite }}
                    </b-table-column>

                    <b-table-column field="recordstatusstring" label="Record Status String" centered v-slot="props">
                        {{ props.row.recordstatusstring }}
                    </b-table-column>

                    <b-table-column field="recordaddress" label="Record Address" centered v-slot="props">
                        {{ props.row.recordaddress }}
                    </b-table-column>

                    <b-table-column field="recordreadsize" label="Record Read Size" centered v-slot="props">
                        {{ props.row.recordreadsize }}
                    </b-table-column>

                    <b-table-column field="recordtypes" label="Record Types" centered v-slot="props">
                        {{ props.row.recordtypes }}
                    </b-table-column>

                    <b-table-column field="recordnum" label="Record Num" centered v-slot="props">
                        {{ props.row.recordnum }}
                    </b-table-column>

                    <b-table-column field="dcurrent" label="Current" centered v-slot="props">
                        {{ props.row.dcurrent }}
                    </b-table-column>

                    <b-table-column field="recordpos" label="Record Pos" centered v-slot="props">
                        {{ props.row.recordpos }}
                    </b-table-column>

                    <b-table-column field="recordbuffer" label="Record Buffer" centered v-slot="props">
                        {{ props.row.recordbuffer }}
                    </b-table-column>

                    <b-table-column field="recordbufferfull" label="Record Buffer Full" centered v-slot="props">
                        {{ props.row.recordbufferfull }}
                    </b-table-column>

                    <b-table-column field="minvoltagetime" label="V min Time" centered v-slot="props">
                        {{ props.row.minvoltagetime }}
                    </b-table-column>

                  <b-table-column field="minvoltagetimehard" label="V min Time hard" centered v-slot="props">
                    {{ props.row.minvoltagetimehard }}
                  </b-table-column>

                    <b-table-column field="lasterrorcmd" label="Last Error CMD" centered v-slot="props">
                        {{ props.row.lasterrorcmd }}
                    </b-table-column>

                    <b-table-column field="lasterrorcode" label="Last Error Code" centered v-slot="props">
                        {{ props.row.lasterrorcode }}
                    </b-table-column>

                    <b-table-column field="canrecieveid" label="CAN Receive ID" centered v-slot="props">
                        {{ props.row.canrecieveid }}
                    </b-table-column>

                    <b-table-column field="cansendid1" label="CAN Send ID1" centered v-slot="props">
                        {{ props.row.cansendid1 }}
                    </b-table-column>

                    <b-table-column field="cansendid2" label="CAN Send ID2" centered v-slot="props">
                        {{ props.row.cansendid2 }}
                    </b-table-column>

                    <b-table-column field="cansendid3" label="CAN Send ID3" centered v-slot="props">
                      {{ props.row.cansendid3 }}
                    </b-table-column>

                    <b-table-column field="cansendid4" label="CAN Send ID4" centered v-slot="props">
                      {{ props.row.cansendid4 }}
                    </b-table-column>

                    <b-table-column field="canspeed" label="CAN Speed" centered v-slot="props">
                        {{ props.row.canspeed }}
                    </b-table-column>

                    <b-table-column field="safetydisabled" label="Safety Disabled" centered v-slot="props">
                        {{ props.row.safetydisabled }}
                    </b-table-column>

                    <b-table-column field="secondssinceboot" label="Seconds since Boot" centered v-slot="props">
                        {{ props.row.secondssinceboot }}
                    </b-table-column>

                    <b-table-column field="dayssinceboot" label="Days since Boot" centered v-slot="props">
                        {{ props.row.dayssinceboot }}
                    </b-table-column>

                    <b-table-column field="secondsawake" label="Seconds Awake" centered v-slot="props">
                        {{ props.row.secondsawake }}
                    </b-table-column>

                    <b-table-column field="hoursawake" label="Hours Awake" centered v-slot="props">
                        {{ props.row.hoursawake }}
                    </b-table-column>

                    <b-table-column field="deltaTLifecycleHours" label="Δ Lifecycle Hours" centered v-slot="props">
                        {{ props.row.deltaTLifecycleHours }}
                    </b-table-column>

                  <b-table-column field="tpscurrentstyle" label="TPS current Style" centered v-slot="props">
                    {{ props.row.tpscurrentstyle }}
                  </b-table-column>


                    <template slot="detail" slot-scope="props">
                        <article class="media" style="text-alin: left">
                            <p>
                                <strong>Customer message:</strong>
                                <br>
                                {{ props.row.customermessage }}
                            </p>
                        </article>
                    </template>
                </b-table>

            </b-tab-item>

            <b-tab-item label="Export">
                <template v-if="selected">
                    <pre>{{ selected }}</pre>
                </template>
                <template v-else>
                    <p>No item is selected</p>
                </template>
            </b-tab-item>
        </b-tabs>

  </section>
</template>

<script>
    export default {
      name: 'TelemetrydataManager',
        data() {
            return {
                data: [],
                selected: null,
                tableHeight: (window.innerHeight - 200),
                total: 0,
                q: '',
                qc: '',
                loading: false,
                sortField: 'id',
                sortOrder: 'desc',
                defaultSortOrder: 'desc',
                page: 1,
                pageSize: 20,
                defaultOpenedDetails: []
            }
        },
        methods: {
          shouldHighlightRed(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (numericValue === 0) return false;
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selected.maxcellvoltagered !== 0 && this.selected.mincellvoltagered !== 0) {
                return (numericValue <= this.selected.mincellvoltagered || numericValue >= this.selected.maxcellvoltagered);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selected.minvoltagered !== 0 && this.selected.maxvoltagered !== 0) {
                return (numericValue <= this.selected.minvoltagered || numericValue >= this.selected.maxvoltagered);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selected.pswarnredtemp > 0 && numericValue >= this.selected.pswarnredtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selected.tempred > 0 && numericValue >= this.selected.tempred);
            }
            return false; // Default: Do not highlight
          },

          shouldHighlightYellow(verticalHeader, column, value) {
            const numericValue = parseFloat(value);
            if (numericValue === 0) return false;
            if (verticalHeader.startsWith('U cell')) {
              // Highlight voltage values for specific cells
              if (this.selected.maxcellvoltageyellow !== 0 && this.selected.mincellvoltageyellow !== 0) {
                return (numericValue <= this.selected.mincellvoltageyellow || numericValue >= this.selected.maxcellvoltageyellow);
              }
            } else if (verticalHeader === 'U total') {
              // Highlight current values for 'I total' row
              if (this.selected.minvoltageyellow !== 0 && this.selected.maxvoltageyellow !== 0) {
                return (numericValue <= this.selected.minvoltageyellow || numericValue >= this.selected.maxvoltageyellow);
              }
            } else if (verticalHeader === 'T pws') {
              return (this.selected.pswarnyellowtemp > 0 && numericValue >= this.selected.pswarnyellowtemp);
            } else if (verticalHeader === 'T cells') {
              return (this.selected.tempyellow > 0 && numericValue >= this.selected.tempyellow);
            }
            return false; // Default: Do not highlight
          },
        
            /*
            * Load async data
            */
            loadAsyncData() {
                this.loading = true;
                this.$http.get(`/api/telemetrydata`, {
                    params: {
                        searchColumn: this.qc,
                        search: this.q,
                        page: this.page,
                        pageSize: this.pageSize,
                        draw: 0,
                        order: [this.sortField + ',' + this.sortOrder]
                    }
                })
                    .then(({data}) => {
                        this.data = data.data;
                        this.total = data.recordsTotal;
                        this.loading = false
                    })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                        this.loading = false;
                        throw error
                    })
            },
            /*
             * Handle page-change event
             */
            onPageChange(page) {
                this.page = page;
                this.loadAsyncData()
            },
            /*
             * Handle sort event
             */
            onSort(field, order) {
                this.sortField = field;
                this.sortOrder = order;
                this.loadAsyncData()
            },
            /*
             * On resize event
             */
            onResize() {
              let offset = 200;
              self.tableHeight = (window.innerHeight - offset).toString() + 'px'
            },
            activateUser() {
              alert("activated")
            },
            closeAllOtherDetails(row) {
              this.defaultOpenedDetails = [row.id]
            }
        },
        mounted() {
            this.$http.get(`/api/user/role`).then(({data}) => {
                this.roles = data;
                this.loadAsyncData();
            });
            let self = this
            let offset = 200
            // to update b-table max-height to have a freeze header (sticky-header makes fixed max-height only regardless the screen height)
            // placed a new issue in Git, see if we get any response.
            self.$nextTick(() => {
              window.addEventListener('resize', () => {
                // debugger
                self.tableHeight = (window.innerHeight - offset).toString() + 'px' // where offset is some kind of constant margin you need from the top
              })
            })
          },
        beforeDestroy() {
          window.removeEventListener('resize', () => {})
        },
      
      computed: {
        columns() {
          return [
            {
              field: "id",
              label: "ID",
              width: "40",
              numeric: true,
              sticky: true,
              sortable: true,
              searchable: false,
              headerClass: "is-sticky-column-one",
              cellClass: "is-sticky-column-one"
            },
            {
              field: "createdAt",
              label: "Date",
              sortable: true,
              searchable: false,
              centered: true
            },
            {
              field: "dname",
              label: "Device Name",
              sortable: true,
              searchable: false,
              centered: true
            },
            {
              field: "sentactive",
              label: "Support request",
              sortable: true,
              centered: true
            },
            {
              field: "email",
              label: "Email",
              sortable: true,
              searchable: false,
              centered: true
            },
            {
              field: "firmwareversion",
              label: "FW Version",
              centered: true,
              sortable: true,
              searchable: false
            },
            {
              field: "firmwarevariante",
              label: "FW Variation",
              centered: true,
              sortable: true,
              searchable: false
            },
            {
              field: "dtype",
              label: "Type",
              centered: true
            },
            {
              field: "revision",
              label: "Revision",
              centered: true
            },
            {
              field: "dstatus",
              label: "Status",
              centered: true
            },
            {
              field: "statusint",
              label: "Status int",
              centered: true
            },
            {
              field: "reason",
              label: "Reason",
              centered: true
            },
            {
              field: "reasonint",
              label: "Reason int",
              centered: true
            },
            {
              field: "userOff",
              label: "User Off",
              centered: true
            },
            {
              field: "customermessage",
              label: "Customer message",
              cellClass: "truncate",
              width: "80",
              text: true,
              centered: true,
              vSlot: "props",
              template: "<a @click=\"props.toggleDetails(props.row)\">\n" +
                      "            {{ props.row.customermessage }}\n" +
                      "          </a>"
            },
            {
              field: "dpassword",
              label: "Password",
              centered: true
            },
            {
              field: "resetEeprom",
              label: "Reset EEprom",
              centered: true
            },
            {
              field: "vcurrent",
              label: "V current",
              centered: true
            },
            {
              field: "v1current",
              label: "V1 current",
              centered: true
            },
            {
              field: "v2current",
              label: "V2 current",
              centered: true
            },
            {
              field: "v3current",
              label: "V3 current",
              centered: true
            },
            {
              field: "v4current",
              label: "V4 current",
              centered: true
            },
            {
              field: "v5current",
              label: "V5 current",
              centered: true
            },
            {
              field: "v6current",
              label: "V6 current",
              centered: true
            },
            {
              field: "tcurrent",
              label: "T current",
              centered: true
            },
            {
              field: "vcurrentunfiltered",
              label: "V cur unfiltered",
              centered: true
            },
            {
              field: "v1currentunfiltered",
              label: "V1 cur unfiltered",
              centered: true
            },
            {
              field: "v2currentunfiltered",
              label: "V2 cur unfiltered",
              centered: true
            },
            {
              field: "v3currentunfiltered",
              label: "V3 cur unfiltered",
              centered: true
            },
            {
              field: "v4currentunfiltered",
              label: "V4 cur unfiltered",
              centered: true
            },
            {
              field: "v5currentunfiltered",
              label: "V5 cur unfiltered",
              centered: true
            },
            {
              field: "v6currentunfiltered",
              label: "V6 cur unfiltered",
              centered: true
            },
            {
              field: "tcurrentunfiltered",
              label: "T cur unfiltered",
              centered: true
            },
            {
              field: "vmin",
              label: "V min",
              centered: true
            },
            {
              field: "v1min",
              label: "V1 min",
              centered: true
            },
            {
              field: "v2min",
              label: "V2 min",
              centered: true
            },
            {
              field: "v3min",
              label: "V3 min",
              centered: true
            },
            {
              field: "v4min",
              label: "V4 min",
              centered: true
            },
            {
              field: "v5min",
              label: "V5 min",
              centered: true
            },
            {
              field: "v6min",
              label: "V6 min",
              centered: true
            },
            {
              field: "tmin",
              label: "T min",
              centered: true
            },
            {
              field: "vmax",
              label: "V max",
              centered: true
            },
            {
              field: "v1max",
              label: "V1 max",
              centered: true
            },
            {
              field: "v2max",
              label: "V2 max",
              centered: true
            },
            {
              field: "v3max",
              label: "V3 max",
              centered: true
            },
            {
              field: "v4max",
              label: "V4 max",
              centered: true
            },
            {
              field: "v5max",
              label: "V5 max",
              centered: true
            },
            {
              field: "v6max",
              label: "V6 max",
              centered: true
            },
            {
              field: "tmax",
              label: "T max",
              centered: true
            },
            {
              field: "pstemp",
              label: "PS temp",
              centered: true
            },
            {
              field: "pstempmax",
              label: "PS temp max",
              centered: true
            },
            {
              field: "pstempmin",
              label: "PS temp min",
              centered: true
            },
            {
              field: "pscurrent",
              label: "PS current",
              centered: true
            },
            {
              field: "pscurrentmin",
              label: "PS current min",
              centered: true
            },
            {
              field: "pscurrentmax",
              label: "PS current max",
              centered: true
            },
            {
              field: "pscurrentcorrection",
              label: "PS current correction",
              centered: true
            },
            {
              field: "psmaxtemp",
              label: "PS max temp",
              centered: true
            },
            {
              field: "pswarnyellowtemp",
              label: "PS warn yellow temp",
              centered: true
            },
            {
              field: "pswarnredtemp",
              label: "PS warn red temp",
              centered: true
            },
            {
              field: "psmaxcurrent",
              label: "PS max current",
              centered: true
            },
            {
              field: "psantitheftcurrent",
              label: "PS antitheft current",
              centered: true
            },
            {
              field: "psantitheftenabled",
              label: "PS antitheft enabled",
              centered: true
            },
            {
              field: "psresistance",
              label: "PS antitheft resistance",
              centered: true
            },
            {
              field: "psexternalinterfaceenabled",
              label: "PS external interface enabled",
              centered: true
            },
            {
              field: "psswitchhistorypos",
              label: "PS history position",
              centered: true
            },
            {
              field: "psresetEeprom",
              label: "PS reset EEprom",
              centered: true
            },
            {
              field: "psovercurrenttimeout",
              label: "PS overcurrent timeout",
              centered: true
            },
            {
              field: "psvoltagemin",
              label: "PS V min",
              centered: true
            },
            {
              field: "cell1enabled",
              label: "Cell1 enabled",
              centered: true
            },
            {
              field: "cell2enabled",
              label: "Cell2 enabled",
              centered: true
            },
            {
              field: "cell3enabled",
              label: "Cell3 enabled",
              centered: true
            },
            {
              field: "cell4enabled",
              label: "Cell4 enabled",
              centered: true
            },
            {
              field: "cell5enabled",
              label: "Cell5 enabled",
              centered: true
            },
            {
              field: "cell6enabled",
              label: "Cell6 enabled",
              centered: true
            },
            {
              field: "cellbalancing",
              label: "Cell balancing",
              centered: true
            },
            {
              field: "cellov",
              label: "Cell OV",
              centered: true
            },
            {
              field: "celluv",
              label: "Cell UV",
              centered: true
            },
            {
              field: "killswitchpolarity",
              label: "Killswitch polarity",
              centered: true
            },
            {
              field: "killswitchdefault",
              label: "Killswitch default",
              centered: true
            },
            {
              field: "killswitchpermanence",
              label: "Killswitch permanence",
              centered: true
            },
            {
              field: "killswitchenabled",
              label: "Killswitch enabled",
              centered: true
            },
            {
              field: "dcurrent",
              label: "Current",
              centered: true
            },
            {
              field: "currentmin",
              label: "Current min",
              centered: true
            },
            {
              field: "currentmax",
              label: "Current max",
              centered: true
            },
            {
              field: "standbycurrent",
              label: "Current standby",
              centered: true
            },
            {
              field: "standbyvoltage",
              label: "V standby",
              centered: true
            },
            {
              field: "balancingminvoltage",
              label: "Balancing V min",
              centered: true
            },
            {
              field: "balancingmaxdifference",
              label: "Balancing max diff",
              centered: true
            },
            {
              field: "tempyellow",
              label: "T yellow",
              centered: true
            },
            {
              field: "tempred",
              label: "T red",
              centered: true
            },
            {
              field: "tempmax",
              label: "T max",
              centered: true
            },
            {
              field: "mincellvoltage",
              label: "Cell V min",
              centered: true
            },
            {
              field: "mincellvoltageyellow",
              label: "Cell V min yellow",
              centered: true
            },
            {
              field: "mincellvoltagered",
              label: "Cell V min red",
              centered: true
            },
            {
              field: "maxcellvoltage",
              label: "Cell V max",
              centered: true
            },
            {
              field: "maxcellvoltageyellow",
              label: "Cell V max yellow",
              centered: true
            },
            {
              field: "maxcellvoltagered",
              label: "Cell V max red",
              centered: true
            },
            {
              field: "maxvoltage",
              label: "V max",
              centered: true
            },
            {
              field: "maxvoltageyellow",
              label: "V max yellow",
              centered: true
            },
            {
              field: "maxvoltagered",
              label: "V max red",
              centered: true
            },
            {
              field: "minvoltage",
              label: "V min",
              centered: true
            },
            {
              field: "minvoltageyellow",
              label: "V min yellow",
              centered: true
            },
            {
              field: "minvoltagered",
              label: "V min red",
              centered: true
            },
            {
              field: "maxchargecurrent",
              label: "Charge C max",
              centered: true
            },
            {
              field: "maxdischargecurrent",
              label: "Discharge C max",
              centered: true
            },
            {
              field: "overcurrenttimeout",
              label: "OC timeout",
              centered: true
            },
            {
              field: "filterfactor",
              label: "Filter factor",
              centered: true
            },
            {
              field: "errorpermanence",
              label: "Error permanence",
              centered: true
            },
            {
              field: "softclose",
              label: "Softclose",
              centered: true
            },
            {
              field: "capacity",
              label: "Capacity",
              centered: true
            },
            {
              field: "voltagefull",
              label: "V full",
              centered: true
            },
            {
              field: "soc",
              label: "SOC",
              centered: true
            },
            {
              field: "eremain",
              label: "E remain",
              centered: true
            },
            {
              field: "beeperOn",
              label: "Beeper On",
              centered: true
            },
            {
              field: "resettofull",
              label: "Reset to full",
              centered: true
            },
            {
              field: "resetstatistics",
              label: "Reset statistics",
              centered: true
            },
            {
              field: "hasnewpowerswitch",
              label: "New PS",
              centered: true
            },
            {
              field: "useroutpolarity",
              label: "Polarity user out",
              centered: true
            },
            {
              field: "recordstatus",
              label: "Record status",
              centered: true
            },
            {
              field: "recordstatuswrite",
              label: "Record status write",
              centered: true
            },
            {
              field: "recordstatusstring",
              label: "Record status string",
              centered: true
            },
            {
              field: "recordaddress",
              label: "Record address",
              centered: true
            },
            {
              field: "recordreadsize",
              label: "Record read size",
              centered: true
            },
            {
              field: "recordtypes",
              label: "Record types",
              centered: true
            },
            {
              field: "recordnum",
              label: "Record num",
              centered: true
            },
            {
              field: "recordpos",
              label: "Record pos",
              centered: true
            },
            {
              field: "recordbuffer",
              label: "Record buffer",
              centered: true
            },
            {
              field: "recordbufferfull",
              label: "Record buffer full",
              centered: true
            },
            {
              field: "minvoltagetime",
              label: "V min time",
              centered: true
            },
            {
              field: "lasterrorcmd",
              label: "Last error cmd",
              centered: true
            },
            {
              field: "lasterrorcode",
              label: "Last error code",
              centered: true
            },
            {
              field: "canrecieveid",
              label: "CAN receive ID",
              centered: true
            },
            {
              field: "cansendid1",
              label: "CAN send ID1",
              centered: true
            },
            {
              field: "cansendid2",
              label: "CAN send ID2",
              centered: true
            },
            {
              field: "canspeed",
              label: "CAN speed",
              centered: true
            },
            {
              field: "safetydisabled",
              label: "Safety disabled",
              centered: true
            },
            {
              field: "secondssinceboot",
              label: "Sec since boot",
              centered: true
            },
            {
              field: "dayssinceboot",
              label: "Days since boot",
              centered: true
            },
            {
              field: "secondsawake",
              label: "Sec awake",
              centered: true
            },
            {
              field: "hoursawake",
              label: "Hours awake",
              centered: true
            },
            {
              field: "deltaTLifecycleHours",
              label: "D lifecycle hours",
              centered: true
            }
          ];
        }
      }
    }
</script>

<style scoped>
  .table-wrapper td {
    vertical-align: middle;
  }
  .my-table.table-wrapper { overflow: auto !important; }

  .is-sticky-column-one {
    background-color: #b6aba1 !important;
    color: #000000 !important;
  }
  .is-sticky-column-two {
    background-color: #f1c725 !important;
    color: #000000 !important;
  }

  .highlighted-value-red {
    color: red !important;
  }

  .highlighted-value-yellow {
    color: #ffc600 !important;
  }

  pre {
      background-color: inherit;
      color: inherit;
      font-size: inherit;
      overflow-x: inherit;
      padding: inherit;
      white-space: pre;
      word-wrap: normal;
  }
  .truncate {
    white-space: nowrap !important;
    /*overflow: hidden !important;*/
    text-overflow: ellipsis !important;
  }

</style>
